import { useWeb3React } from '@web3-react/core';
import { fetchInternalWalletScreening } from 'api';
import useAuth from 'hooks/useAuth';
import React, { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { ReduxState, staticDispatch } from 'state';
import { addBlockedWallet, addNotBlockedWallet } from 'state/restricted';
import styled from 'styled-components/macro';

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 33px;
  text-align: center;
  border-bottom: 1px solid black;
  background-color: #f9e273;
  color: #000;
  font-size: 12px;
  padding: 7px;
`;

export const useIsRestricted = (): boolean => {
  const restricted = useSelector((state: ReduxState) => state.restricted);
  const { restrictedCountry, restrictedWallet } = restricted;
  const { account } = useWeb3React();
  return (
    !!restrictedCountry ||
    // Also restrict until everything is loaded properly
    !!(
      account &&
      (restrictedWallet === undefined || account === restrictedWallet)
    )
  );
};

export function RestrictedBanner() {
  const restricted = useSelector((state: ReduxState) => state.restricted);
  const { restrictedCountry, restrictedWallet, restrictedWalletMessage } =
    restricted;

  // We have to log out currently geoblocked users
  const { account } = useWeb3React();
  const { logout } = useAuth();
  const logoutRan = useRef(false);
  // const { executeCaptchaAsync, renderCaptchaForm } = useInvisibleReCaptcha(!!account)

  const accountLowercase = useMemo(() => account?.toLowerCase(), [account]);

  // Do wallet check here
  useEffect(() => {
    if (!account) {
      return;
    }
    fetchInternalWalletScreening(account)
      .then(blockedMessage => {
        if (blockedMessage) {
          staticDispatch(
            addBlockedWallet({ reason: blockedMessage, wallet: account })
          );
          return;
        }
        staticDispatch(addNotBlockedWallet());
      })
      .catch(error => {
        console.warn(error);
        // If there is API error, we will keep app running to prevent
        // users from experiencing downtime
        staticDispatch(addNotBlockedWallet());
        // staticDispatch(
        //   addBlockedWallet({
        //     wallet: account,
        //     reason: error?.message || 'Unknown error',
        //   })
        // );
      });
  }, [account]);

  useEffect(() => {
    if (account && restrictedCountry && !logoutRan.current) {
      logoutRan.current = true;
      logout();
    }
  }, [account, logout, restrictedCountry]);

  useEffect(() => {
    if (accountLowercase && restrictedWallet === accountLowercase) {
      logoutRan.current = true;
      logout();
    }
  }, [accountLowercase, logout, restrictedWallet]);

  if (restrictedCountry) {
    return <Wrap>Notice: Farming from {restrictedCountry} is blocked.</Wrap>;
  }

  if (restrictedWalletMessage) {
    return <Wrap>{restrictedWalletMessage || 'Wallet is restricted'}</Wrap>;
  }

  return null;
}
