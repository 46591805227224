import { useEffect, useMemo } from 'react';
import BigNumber from 'bignumber.js';
import { Toast, toastTypes } from '@idexio/dev-idex-swap-uikit';
import { staticDispatch, useSelector } from 'state';
import useRefresh from 'hooks/useRefresh';
import { convertToKebabCase } from 'utils/string';
import { FarmConfig } from 'config/constants/types';
import { fetchFarmsPublicDataAsync } from './farms';
import { Farm } from './types';
import { fetchPrices } from './prices';
import {
  clear as clearToast,
  remove as removeToast,
  push as pushToast,
} from './toasts';
import { fetchTokenStats } from './tokenStats';

export const useFetchPublicData = () => {
  const { slowRefresh } = useRefresh();
  useEffect(() => {
    // TODO: use "useActiveWindowInterval" from main frontend
    // if (document.hidden) {
    //   return;
    // }
    staticDispatch(fetchFarmsPublicDataAsync());
  }, [slowRefresh]);
};

export const useFarms = (): Farm[] => useSelector(state => state.farms.data);

// export const useFarmFromPid = (pid: number): Farm | undefined =>
//   useSelector(state => state.farms.data.find(f => f.pid === pid));

export const useExchangeInfo = () =>
  useSelector(state => state.exchangeInfo.data);

export const useFarmUser = (farmConfig: FarmConfig) => {
  const farm = useSelector(state =>
    state.farms.data.find(
      f =>
        f.pid === farmConfig.pid &&
        f.farmContract.contractAddress ===
          farmConfig.farmContract.contractAddress
    )
  );
  if (!farm) {
    return {};
  }
  return {
    allowance: farm.userData
      ? new BigNumber(farm.userData.allowance)
      : undefined,
    tokenBalance: farm.userData
      ? new BigNumber(farm.userData.tokenBalance)
      : new BigNumber(0),
    stakedBalance: farm.userData
      ? new BigNumber(farm.userData.stakedBalance)
      : new BigNumber(0),
    earnings1: farm.userData
      ? new BigNumber(farm.userData.earnings1)
      : new BigNumber(0),
    earnings2: farm?.userData?.earnings2
      ? new BigNumber(farm.userData.earnings2)
      : new BigNumber(0),
  };
};

export const useToast = () => {
  const helpers = useMemo(() => {
    const push = (toast: Toast) => staticDispatch(pushToast(toast));

    return {
      toastError: (title: string, description?: string) => {
        return push({
          id: convertToKebabCase(title),
          type: toastTypes.DANGER,
          title,
          description,
        });
      },
      toastInfo: (title: string, description?: string) => {
        return push({
          id: convertToKebabCase(title),
          type: toastTypes.INFO,
          title,
          description,
        });
      },
      toastSuccess: (title: string, description?: string) => {
        return push({
          id: convertToKebabCase(title),
          type: toastTypes.SUCCESS,
          title,
          description,
        });
      },
      toastWarning: (title: string, description?: string) => {
        return push({
          id: convertToKebabCase(title),
          type: toastTypes.WARNING,
          title,
          description,
        });
      },
      push,
      remove: (id: string) => staticDispatch(removeToast(id)),
      clear: () => staticDispatch(clearToast()),
    };
  }, []);

  return helpers;
};

export const useFetchPriceList = () => {
  const { slowRefresh } = useRefresh();
  const exchangeInfo = useExchangeInfo();
  useEffect(() => {
    if (exchangeInfo?.maticUsdPrice) {
      staticDispatch(
        fetchPrices({ maticPriceUsd: exchangeInfo?.maticUsdPrice })
      );
    }
  }, [slowRefresh, exchangeInfo]);
};

export const useApiPrices = () => useSelector(state => state.prices.data);

export const useFetchAndPollTokenStats = () => {
  const { slowRefresh } = useRefresh();

  useEffect(() => {
    staticDispatch(fetchTokenStats());
  }, [slowRefresh]);
};
