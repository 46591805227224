import React from 'react';
import { HelpIcon, Tooltip } from '@idexio/dev-idex-swap-uikit';

import { FarmContract } from 'config/constants/types';
import EarningsDetails from './EarningsDetails';

export default function RewardsInfoIcon({
  earnings1,
  earnings1Usd,
  earnings2,
  earnings2Usd,
  farmContract,
  isMobile,
}: {
  earnings1?: number;
  earnings1Usd?: number;
  earnings2?: number;
  earnings2Usd?: number;
  farmContract: FarmContract;
  isMobile: boolean;
}) {
  const tooltipContent = (
    <EarningsDetails
      earnings={
        farmContract.rewardToken2
          ? [
              {
                earningUsd: earnings1Usd,
                earningInToken: earnings1,
                symbol: farmContract.rewardToken1.symbol,
              },
              {
                earningUsd: earnings2Usd,
                earningInToken: earnings2,
                symbol: farmContract.rewardToken2.symbol,
              },
            ]
          : [
              {
                earningUsd: earnings1Usd,
                earningInToken: earnings1,
                symbol: farmContract.rewardToken1.symbol,
              },
            ]
      }
    />
  );
  return (
    <Tooltip
      display="inline-block"
      height="1px"
      content={tooltipContent}
      position={isMobile ? 'upToRight' : 'up'}
    >
      <HelpIcon color="textSubtle" width="17px" mb="-4px" mt="-4px" />
    </Tooltip>
  );
}
