import config from 'config/constants';

/**
 * Get current proposed gas price, based on official API
 * https://docs.polygon.technology/docs/develop/tools/polygon-gas-station/
 *
 * Note: alternative api is https://gpoly.blockscan.com/gasapi.ashx?apikey=key&method=gasoracle
 *                          json?.result?.SafeGasPrice
 */
export const fetchCurrentPolygonGasInWei = (): Promise<number> =>
  fetch(config.gasStationUrl)
    .then(response => response.json())
    .then(json => {
      const gwei = json?.safeLow?.maxFee;
      if (!gwei) {
        throw new Error('Unknown network gas price');
      }
      // Conversion to wei
      return Number(gwei) * 1000000000;
    });
