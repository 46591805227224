import { configureStore } from '@reduxjs/toolkit';
import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector as reduxUseSelector,
} from 'react-redux';
import { setStaticDispatch } from 'api/geoRestricted';
import farmsReducer from './farms';
import toastsReducer from './toasts';
import pricesReducer from './prices';
import exchangeInfoReducer from './exchangeInfo';
import contractsConfigReducer from './contractsConfig';
import tokenStatsReducer from './tokenStats';
import restrictedReducer from './restricted';

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    restricted: restrictedReducer,
    farms: farmsReducer,
    toasts: toastsReducer,
    prices: pricesReducer,
    tokenStats: tokenStatsReducer,
    contractsConfig: contractsConfigReducer,
    exchangeInfo: exchangeInfoReducer,
  },
});

export type ReduxState = ReturnType<typeof store.getState>;

/**
 * @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
 */
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useSelector: TypedUseSelectorHook<ReduxState> = reduxUseSelector;

/**
 * Redux dispatch which can be used without recalculations in react hooks
 */
export const staticDispatch: AppDispatch = store.dispatch;

setStaticDispatch(staticDispatch);

export default store;
