import { ethers } from 'ethers';

export const productionTokens = {
  matic: {
    symbol: 'MATIC',
    address: ethers.constants.AddressZero,
    decimals: 18,
    projectLink: 'https://polygon.technology/',
  },
  eth: {
    symbol: 'ETH',
    address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    decimals: 18,
    projectLink: 'https://weth.io/',
  },
  usdc: {
    symbol: 'USDC',
    address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    decimals: 6,
    projectLink: 'https://www.centre.io/',
  },
  usdt: {
    symbol: 'USDT',
    address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
    decimals: 6,
    projectLink: 'https://tether.to/',
  },
  dai: {
    symbol: 'DAI',
    address: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    decimals: 18,
    projectLink: 'https://makerdao.com/',
  },
  idex: {
    symbol: 'IDEX',
    address: '0x9Cb74C8032b007466865f060ad2c46145d45553D',
    decimals: 18,
    projectLink: 'https://idex.io/',
  },
  wbtc: {
    symbol: 'WBTC',
    address: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
    decimals: 8,
    projectLink: 'https://wbtc.network/',
  },
  aave: {
    symbol: 'AAVE',
    address: '0xD6DF932A45C0f255f85145f286eA0b292B21C90B',
    decimals: 18,
    projectLink: 'https://aave.com/',
  },
  crv: {
    symbol: 'CRV',
    address: '0x172370d5Cd63279eFa6d502DAB29171933a610AF',
    decimals: 18,
    projectLink: 'https://www.curve.fi/',
  },
  cvx: {
    symbol: 'CVX',
    address: '0x4257EA7637c355F81616050CbB6a9b709fd72683',
    decimals: 18,
    projectLink:
      'https://polygonscan.com/token/0x4257ea7637c355f81616050cbb6a9b709fd72683',
  },
  // spell: {
  //   symbol: 'SPELL',
  //   address: {      '137': '0xcdB3C70CD25FD15307D84C4F9D37d5C043B33Fb2',    },
  //   decimals: 18,
  //   projectLink:
  //     'https://polygonscan.com/token/0xcdb3c70cd25fd15307d84c4f9d37d5c043b33fb2',
  // },
  mai: {
    symbol: 'MAI',
    address: '0xa3Fa99A148fA48D14Ed51d610c367C61876997F1',
    decimals: 18,
    projectLink: 'https://www.mai.finance/',
  },
  mana: {
    symbol: 'MANA',
    address: '0xA1c57f48F0Deb89f569dFbE6E2B7f46D33606fD4',
    decimals: 18,
    projectLink: 'https://decentraland.org/',
  },
  // sand: {
  //   symbol: 'SAND',
  //   address:  '0xC6d54D2f624bc83815b49d9c2203b1330B841cA0' },
  //   decimals: 18,
  //   projectLink: 'http://www.sandbox.game/',
  // },
  link: {
    symbol: 'LINK',
    address: '0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39',
    decimals: 18,
    projectLink: 'https://chain.link/',
  },
  ghst: {
    symbol: 'GHST',
    address: '0x385Eeac5cB85A38A9a07A70c73e0a3271CfB54A7',
    decimals: 18,
    projectLink: 'https://aavegotchi.com/',
  },
  syn: {
    symbol: 'SYN',
    address: '0xf8F9efC0db77d8881500bb06FF5D6ABc3070E695',
    decimals: 18,
    projectLink: 'https://synapseprotocol.com/',
  },
  sx: {
    symbol: 'SX',
    address: '0x840195888db4d6a99ed9f73fcd3b225bb3cb1a79',
    decimals: 18,
    projectLink: 'https://sx.technology/',
  },
  quick: {
    symbol: 'QUICK',
    address: '0x831753dd7087cac61ab5644b308642cc1c33dc13',
    decimals: 18,
    projectLink: 'https://quickswap.exchange/',
  },
  bifi: {
    symbol: 'BIFI',
    address: '0xfbdd194376de19a88118e84e279b977f165d01b8',
    decimals: 18,
    projectLink: 'https://app.beefy.finance/#/polygon',
  },
  fxs: {
    symbol: 'FXS',
    address: '0x1a3acf6D19267E2d3e7f898f42803e90C9219062',
    decimals: 18,
    projectLink: 'https://fxs.finance/',
  },
  frax: {
    symbol: 'FRAX',
    address: '0x45c32fA6DF82ead1e2EF74d17b76547EDdFaFF89',
    decimals: 18,
    projectLink: 'https://frax.finance/',
  },
  sushi: {
    symbol: 'SUSHI',
    address: '0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a',
    decimals: 18,
    projectLink:
      'https://polygonscan.com/token/0x0b3F868E0BE5597D5DB7fEB59E1CADBb0fdDa50a',
  },
  knc: {
    symbol: 'KNC',
    address: '0x1C954E8fe737F99f68Fa1CCda3e51ebDB291948C',
    decimals: 18,
    projectLink:
      'https://polygonscan.com/token/0x1C954E8fe737F99f68Fa1CCda3e51ebDB291948C',
  },
  uni: {
    symbol: 'UNI',
    address: '0xb33EaAd8d922B1083446DC23f610c2567fB5180f',
    decimals: 18,
    projectLink:
      'https://polygonscan.com/token/0xb33EaAd8d922B1083446DC23f610c2567fB5180f',
  },
  qi: {
    symbol: 'QI',
    address: '0x580A84C73811E1839F75d86d75d88cCa0c241fF4',
    decimals: 18,
    projectLink:
      'https://polygonscan.com/token/0x580A84C73811E1839F75d86d75d88cCa0c241fF4',
  },
  dg: {
    symbol: 'DG',
    address: '0xef938b6da8576a896f6E0321ef80996F4890f9c4',
    decimals: 18,
    projectLink:
      'https://polygonscan.com/token/0xef938b6da8576a896f6E0321ef80996F4890f9c4',
  },
  algb: {
    symbol: 'ALGB',
    address: '0x0169eC1f8f639B32Eec6D923e24C2A2ff45B9DD6',
    decimals: 18,
    projectLink:
      'https://polygonscan.com/token/0x0169eC1f8f639B32Eec6D923e24C2A2ff45B9DD6',
  },
  // ADD_NEW_PROD_PLACEHOLDER
};

export const stagingTokens = {
  matic: {
    symbol: 'MATIC',
    address: ethers.constants.AddressZero,
    decimals: 18,
    projectLink: 'https://polygon.technology/',
  },
  idex: {
    symbol: 'IDEX',
    address: '0x872b0fBf3eE34D40BCB437a07A030e70AF514192',
    decimals: 18,
  },
  dil: {
    symbol: 'DIL',
    address: '0x245c15Bf2e2Ab28E9DCC46210ea172c42Dd1c00B',
    decimals: 18,
  },
  pip: {
    symbol: 'PIP',
    address: '0xedEf48aC7711BDafEea7bcB903121C13310aFc64',
    decimals: 8,
  },
  usd: {
    symbol: 'USD',
    address: '0x475056af4D4E64a960b4ba10DACF6d5bD1D653d1',
    decimals: 2,
  },
  eth: {
    symbol: 'ETH',
    address: '0xC8dBB620B2bcF497cc840BCbbb108338c0d6496b',
    decimals: 18,
  },
};

export const devTokens = {
  matic: {
    symbol: 'MATIC',
    address: ethers.constants.AddressZero,
    decimals: 18,
    projectLink: 'https://polygon.technology/',
  },
  idex: {
    symbol: 'IDEX',
    address: '0x20D6752FBd5f9aD55AF476e7c1EFA041Ac65b445',
    decimals: 18,
    projectLink: 'https://idex.io/',
  },
  dil: {
    symbol: 'DIL',
    address: '0x8063668cD13d4D74D68D6aB79D0c2281f427E2A2',
    decimals: 18,
  },
  pip: {
    symbol: 'PIP',
    address: '0xF9D7A646F2bb1a563A175B6028d5c5E322a6E95a',
    decimals: 8,
  },
  usd: {
    symbol: 'USD',
    address: '0xBf97fb9c48993Ab202b204cd6918AB487C7Cb076',
    decimals: 2,
  },
};
