import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';
import { useWeb3React } from '@web3-react/core';

import useAuth from 'hooks/useAuth';
import Header from './Header';
import UserBlock from './UserBlock';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const BodyWrapper = styled.div`
  position: relative;
  display: flex;
  padding-top: 24px;
  ${({ theme }) => theme.mediaQueries.md} {
    padding-top: 48px;
  }
`;

const InnerNoSidebar = styled.div`
  flex-grow: 1;
  max-width: 100%;
`;

const Menu = ({ children }: { children: ReactNode }) => {
  const { account } = useWeb3React();
  const { login, logout } = useAuth();
  // const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext);
  // const { isDark, toggleTheme } = useTheme();
  // const idexPriceUsd = usePriceIdexUsd();
  // const { breakpointEqualOrSmallerThan } = useMatchBreakpoints();
  // const isMobile = breakpointEqualOrSmallerThan('md');
  return (
    <Wrapper>
      <Header
        afterMenuItem={
          <UserBlock
            account={account || undefined}
            login={login}
            logout={logout}
          />
        }
      />
      <BodyWrapper>
        <InnerNoSidebar>{children}</InnerNoSidebar>
      </BodyWrapper>
    </Wrapper>
  );
};

export default Menu;
