import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import {
  Text,
  Button,
  Input,
  InputProps,
  Flex,
} from '@idexio/dev-idex-swap-uikit';

import useI18n from 'hooks/useI18n';

const getBoxShadow = ({ isWarning = false, theme }) => {
  if (isWarning) {
    // return theme.shadows.warning;
    return theme.shadows.highlight;
  }
  return theme.shadows.inset;
};

const StyledTokenInput = styled.div<InputProps>`
  display: flex;
  flex-direction: column;
  border-radius: 1px;
  margin: 16px 0;
  border: 1px solid
    ${({ theme, isWarning }) =>
      isWarning ? 'transparent' : theme.colors.borderColor};
  background-color: ${({ theme }) => theme.colors.card};

  box-shadow: ${getBoxShadow};
  color: ${({ theme }) => theme.colors.text};
  width: 100%;
`;

const StyledInput = styled(Input)`
  box-shadow: none;
  width: 60px;
  font-size: 16px;
  ${({ theme }) => theme.mediaQueries.xs} {
    width: 80px;
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    width: auto;
  }
  &[type='number']:focus {
    outline: none;
    box-shadow: 0px 0px 4px ${({ theme }) => theme.colors.primary};
  }

  /* https://stackoverflow.com/questions/3790935/can-i-hide-the-html5-number-input-s-spin-box */
  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

const StyledErrorMessage = styled(Text)`
  a {
    display: inline;
  }
`;

interface ModalInputProps {
  max: number;
  symbol: string;
  onSelectMax?: () => void;
  onChange: (event: React.FormEvent<HTMLInputElement>) => void;
  value: string;
  inputTitle?: string;
}

const ModalInput: React.FC<ModalInputProps> = ({
  max,
  symbol,
  onChange,
  onSelectMax,
  value,
  inputTitle,
}) => {
  const TranslateString = useI18n();
  const isBalanceZero = !max;
  const isOverAvailableBalance = useMemo(
    () => Number(value) > max,
    [max, value]
  );
  const isBalanceError = isBalanceZero || isOverAvailableBalance;

  const displayBalance = isBalanceZero ? '0' : max?.toFixed(4);

  return (
    <div style={{ position: 'relative' }}>
      <StyledTokenInput isWarning={isBalanceError}>
        <Flex justifyContent="space-between" py="2" px="3" background="#1e1e27">
          <Text fontSize="14px">{inputTitle}</Text>
          <Text
            fontSize="14px"
            color={isBalanceError ? 'primaryAlt' : 'text'}
            fontWeight={isBalanceError ? 'bold' : 'initial'}
          >
            {TranslateString(1120, 'Balance')}:{' '}
            {displayBalance.toLocaleString()}
          </Text>
        </Flex>
        <Flex
          alignItems="center"
          pl="1"
          pr="3"
          py="2"
          justifyContent="space-around"
        >
          <StyledInput
            onChange={onChange}
            placeholder="0"
            type="number"
            step="0.01"
            noBorder
            value={value}
          />
          <Button scale="sm" variant="text" onClick={onSelectMax} mr="8px">
            {TranslateString(452, 'Max')}
          </Button>
          <Text fontSize="16px">{symbol}</Text>
        </Flex>
      </StyledTokenInput>
      {isBalanceError && (
        <StyledErrorMessage
          fontSize="14px"
          pb="1"
          textAlign="right"
          color="primaryAlt"
        >
          {isBalanceZero
            ? 'No tokens to stake.'
            : isOverAvailableBalance
            ? 'Not enough tokens.'
            : 'Error'}
        </StyledErrorMessage>
      )}
    </div>
  );
};

export default ModalInput;
