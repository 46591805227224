import React from 'react';
import styled from 'styled-components/macro';

import config from 'config/constants';
import bannerStakingImage from './images/bannerStaking.png';
import bannerFarmBonusImage from './images/bannerFarmBonus.png';

const ScrollWrap = styled.div`
  overflow-x: auto;
  padding-bottom: 4px;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: fit-content(100%) fit-content(100%);
  gap: 16px;
  justify-content: center;
  min-width: fit-content;

  > a {
    min-width: 310px;
  }
`;

const MarketingBox = ({ href, image }: { href: string; image: string }) => {
  return (
    <a href={href}>
      <img src={image} alt="farm" />
    </a>
  );
};

const MarketingArea: React.FC = () => {
  const { exchangeUrl } = config;
  return (
    <ScrollWrap className="globalScrollbarStyle globalHideScrollbarForTouch">
      <Wrapper>
        <MarketingBox
          href={`${exchangeUrl}/rewards/staking/replicator`}
          image={bannerStakingImage}
        />
        <MarketingBox
          href={`${exchangeUrl}/liquidity/add/IDEX/USDC`}
          image={bannerFarmBonusImage}
        />
      </Wrapper>
    </ScrollWrap>
  );
};

export default MarketingArea;

// const Wrapper = styled.div`
//   display: grid;
//   grid-template-columns: fit-content(100%) fit-content(100%);
//   gap: 20px;
//   justify-content: center;
//   min-width: fit-content;
// `;

// const Box = styled.div<{ flex?: string }>`
//   display: flex;
//   min-width: 305px;
//   align-items: center;
//   padding: 14px 24px;
//   background: #171720;
//   border: 1px solid #272727;
//   font-size: 16px;
//   color: ${({ theme }) => theme.colors.primaryAlt};
//   & > div > img {
//     height: 28px;
//     max-width: initial;
//   }
// `;

// const MarketingBox = ({
//   children,
//   href,
//   linkTitle,
//   image,
// }: {
//   children: ReactNode;
//   href: string;
//   linkTitle: string;
//   image: string;
// }) => {
//   return (
//     <Box>
//       <div>
//         <img src={image} alt="farm" />
//       </div>
//       <Text pl={12}>
//         {children}{' '}
//         <Link inline href={href} color="primaryLight">
//           {linkTitle}
//         </Link>
//       </Text>
//     </Box>
//   );
// };

// const MarketingArea: React.FC = () => {
//   const { exchangeUrl } = config;
//   return (
//     <ScrollWrap className="globalScrollbarStyle globalHideScrollbarForTouch">
//       <Wrapper>
//         <MarketingBox
//           href={`${exchangeUrl}/rewards/staking/replicator`}
//           linkTitle="Stake IDEX"
//           image={cashImage}
//         >
//           Stake IDEX to earn 50% of all trading fees.
//         </MarketingBox>
//         <MarketingBox
//           href={`${exchangeUrl}/liquidity/add/IDEX/ETH`}
//           image={presentImage}
//           linkTitle="Get Started"
//         >
//           Farm IDEX-ETH and earn bonus rewards.
//         </MarketingBox>
//       </Wrapper>
//     </ScrollWrap>
//   );
// };

// export default MarketingArea;
