import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchRewardsPerBlock } from 'utils/rewardPerBlock';

export interface ContractsConfig {
  rewardPerBlock?: {
    [contractAddress: string]: [number, number | undefined] | undefined;
  };
}

export type ContractsConfigState = {
  data: ContractsConfig | null;
};

const initialState: ContractsConfigState = { data: null };

export const fetchAndDispatchRewardPerBlock = createAsyncThunk<
  ContractsConfig['rewardPerBlock'] | null
>('contractsConfig/fetchRewardPerBlock', async () => {
  try {
    return await fetchRewardsPerBlock();
  } catch (error: any) {
    console.error('Unable to fetch data:', error);
    return null;
  }
});

export const contractsConfig = createSlice({
  name: 'contractsConfig',
  initialState,
  reducers: {},
  extraReducers: builder => {
    (builder as any).addCase(
      fetchAndDispatchRewardPerBlock.fulfilled,
      (
        state: ContractsConfigState,
        action: PayloadAction<ContractsConfig['rewardPerBlock'] | null>
      ) => {
        // eslint-disable-next-line no-param-reassign
        state.data = {
          rewardPerBlock: action.payload ?? undefined,
        };
      }
    );
  },
});

export default contractsConfig.reducer;
