import React, { useState, useCallback } from 'react';
import { Box, Button } from '@idexio/dev-idex-swap-uikit';
import { useWeb3React } from '@web3-react/core';

import SpinningRoundedBorder from 'components/SpinningRoundedBorder/SpinningRoundedBorder';
import { useToast } from 'state/hooks';
import { useAllHarvest } from 'hooks/useHarvest';
import UnlockButton from 'components/UnlockButton';
import { Farm } from 'state/types';
import HarvestAllBalance from './HarvestAllBalance';

const HarvestBox = ({
  earningsModalContent,
  farmsWithPendingRewards,
  totalEarningsUsd,
  totalEarningsTokenSymbols,
}: {
  earningsModalContent: React.ReactNode;
  farmsWithPendingRewards: Farm[];
  totalEarningsUsd: number | undefined;
  totalEarningsTokenSymbols: string[];
}) => {
  const [pendingTx, setPendingTx] = useState(false);
  const { account } = useWeb3React();
  const { toastError } = useToast();

  const { onReward } = useAllHarvest(farmsWithPendingRewards);

  const harvestAllFarms = useCallback(async () => {
    setPendingTx(true);
    try {
      await onReward();
    } catch (error) {
      console.warn(error);
      toastError(error?.message);
      // TODO: find a way to handle when the user rejects transaction or it fails
    } finally {
      setPendingTx(false);
    }
  }, [onReward, toastError]);

  return (
    <>
      <HarvestAllBalance
        totalEarningsUsd={totalEarningsUsd}
        totalEarningsTokenSymbols={totalEarningsTokenSymbols}
        earningsModalContent={earningsModalContent}
      />

      {account ? (
        <Button
          id="harvest-all"
          variant="secondary"
          disabled={farmsWithPendingRewards.length <= 0 || pendingTx}
          onClick={harvestAllFarms}
          scale="sm"
          mt="2"
          endIcon={
            pendingTx ? (
              <Box ml="1">
                <SpinningRoundedBorder inline size={24} />
              </Box>
            ) : undefined
          }
          // variant="text"
          // padding={0}
        >
          {pendingTx
            ? 'Harvesting'
            : `Harvest all (${farmsWithPendingRewards.length})`}
        </Button>
      ) : (
        <UnlockButton variant="secondary" scale="sm" mt="2" />
      )}
    </>
  );
};

export default HarvestBox;
