import config from 'config/constants';
import { getMasterchefInstance } from 'hooks/useContract';
import { getWeb3NoAccount } from './web3';

type RatePair = [number, number | undefined];

export async function fetchRewardsPerBlock(): Promise<{
  [contractAddress: string]: RatePair;
}> {
  const rewardsPerBlockList: [number, number | undefined][] = await Promise.all(
    config.farmsContracts.map(async farmContract => {
      const { rewardToken1, rewardToken2 } = farmContract;
      const masterchefContract = getMasterchefInstance(
        getWeb3NoAccount(),
        farmContract
      );
      if (farmContract.contractAbi === 'singleReward') {
        const rewardTokenPerBlock = await masterchefContract.methods
          .rewardTokenPerBlock()
          .call();
        return [
          rewardTokenPerBlock / 10 ** rewardToken1.decimals,
          undefined,
        ] as RatePair;
      }
      // Multi reward
      const rewardsPerBlock = await Promise.all([
        masterchefContract.methods.reward0TokenPerBlock().call(),
        masterchefContract.methods.reward1TokenPerBlock().call(),
      ]);
      if (!rewardToken2 || !rewardsPerBlock[0]) {
        throw new Error(
          `Farm ${farmContract.contractAddress} rewards token2 rate not defined`
        );
      }
      return [
        rewardsPerBlock[0] / 10 ** rewardToken1.decimals,
        rewardsPerBlock[1] / 10 ** rewardToken2.decimals,
      ] as RatePair;
    })
  );

  return config.farmsContracts.reduce(
    (map, farmContract, index) => {
      // eslint-disable-next-line no-param-reassign
      map[farmContract.contractAddress] = rewardsPerBlockList[index];
      return map;
    },
    {} as {
      [contractAddress: string]: [number, number | undefined];
    }
  );
}
