import Web3 from 'web3';
import { HttpProviderOptions } from 'web3-core-helpers';
import { providers } from 'ethers';
import config from 'config/constants';

const httpProvider = new Web3.providers.HttpProvider(config.chainRpc, {
  timeout: 10000,
} as HttpProviderOptions);

const web3NoAccount = new Web3(httpProvider);

export const getWeb3NoAccount = () => {
  return web3NoAccount;
};

/** Search keywords: provider, anonymous, rpc */
export const getEthersProviderNoAccount = () => {
  return new providers.JsonRpcProvider(config.chainRpc, {
    chainId: config.chainId,
    name: config.chainNetwork,
  });
};

export default web3NoAccount;
