/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { farmsConfig } from 'config/constants';
import BigNumber from 'bignumber.js';

import { getBalanceNumber } from 'utils/formatBalance';
import fetchFarms from './fetchFarms';
import {
  fetchFarmUserEarnings,
  fetchFarmUserAllowances,
  fetchFarmUserTokenBalances,
  fetchFarmUserStakedBalances,
} from './fetchFarmUser';
import { Farm } from '../types';

export interface FarmsState {
  data: Farm[];
}

const initialState: FarmsState = { data: [...farmsConfig] };

export const farmsSlice = createSlice({
  name: 'Farms',
  initialState,
  reducers: {
    setFarmsPublicData: (
      state,
      action: {
        payload: Farm[];
      }
    ) => {
      const liveFarmsData: Farm[] = action.payload;
      state.data = state.data.map(farm => {
        const liveFarmData = liveFarmsData.find(
          f =>
            f.pid === farm.pid &&
            f.farmContract.contractAddress === farm.farmContract.contractAddress
        );
        return { ...farm, ...liveFarmData };
      });
    },
    setFarmUserData: (
      state,
      action: {
        payload: { arrayOfUserDataObjects: Farm['userData'][] };
      }
    ) => {
      const { arrayOfUserDataObjects } = action.payload;
      arrayOfUserDataObjects.forEach((userDataEl, index) => {
        state.data[index] = { ...state.data[index], userData: userDataEl };
      });
    },
  },
});

// Actions
export const { setFarmsPublicData, setFarmUserData } = farmsSlice.actions;

// Thunks
export const fetchFarmsPublicDataAsync = () => async dispatch => {
  const farms = await fetchFarms();
  dispatch(setFarmsPublicData(farms));
};

export const fetchFarmUserDataAsync = account => async dispatch => {
  const userFarmAllowances = await fetchFarmUserAllowances(account);
  // console.log('[farmsUserData:1] Allowances', userFarmAllowances);
  const userFarmTokenBalances = await fetchFarmUserTokenBalances(account);
  // console.log('[farmsUserData:2] Balances', userFarmTokenBalances);
  const userStakedBalances = await fetchFarmUserStakedBalances(account);
  // console.log('[farmsUserData:3] Staked', userStakedBalances);
  const userFarmEarnings = await fetchFarmUserEarnings(account);
  // console.log('[farmsUserData:4] Earnings', userFarmEarnings);

  const arrayOfUserDataObjects = userFarmAllowances.map(
    (farmAllowance, index) => {
      const farm = farmsConfig[index];
      const { rewardToken1, rewardToken2 } = farm.farmContract;
      const earnings1FullPrecision = userFarmEarnings[index]?.[0] || '0';
      const earnings2FullPrecision = userFarmEarnings[index]?.[1];
      return {
        allowance: farmAllowance,
        tokenBalance: userFarmTokenBalances[index],
        stakedBalance: userStakedBalances[index],
        earnings1: getBalanceNumber(
          new BigNumber(earnings1FullPrecision),
          rewardToken1.decimals
        ),
        earnings2:
          earnings2FullPrecision && rewardToken2
            ? getBalanceNumber(
                new BigNumber(earnings2FullPrecision),
                rewardToken2.decimals
              )
            : undefined,
      };
    }
  );

  dispatch(setFarmUserData({ arrayOfUserDataObjects }));
};

export default farmsSlice.reducer;
