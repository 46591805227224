// Constructing the two forward-slash-separated parts of the 'Add Liquidity' URL
// Each part of the url represents a different side of the LP pair.
// import { NATIVE_ASSET_SYMBOL, NETWORK_CHAIN_ID } from 'config';
// import { getWbnbAddress } from './addressHelpers';

const getLiquidityUrlPathParts = ({
  baseSymbol,
  quoteSymbol,
}: {
  baseSymbol: string;
  quoteSymbol: string;
}) => {
  return `${baseSymbol}/${quoteSymbol}`;
  // const wBNBAddressString = getWbnbAddress();
  // const quoteTokenAddressString: string = quoteTokenAddress
  //   ? quoteTokenAddress[NETWORK_CHAIN_ID]
  //   : null;
  // const tokenAddressString: string = tokenAddress
  //   ? tokenAddress[NETWORK_CHAIN_ID]
  //   : null;
  // const firstPart =
  //   !quoteTokenAddressString || quoteTokenAddressString === wBNBAddressString
  //     ? NATIVE_ASSET_SYMBOL
  //     : quoteTokenAddressString;
  // const secondPart =
  //   !tokenAddressString || tokenAddressString === wBNBAddressString
  //     ? NATIVE_ASSET_SYMBOL
  //     : tokenAddressString;
  // return `${firstPart}/${secondPart}`;
};

export default getLiquidityUrlPathParts;
