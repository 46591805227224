import React from 'react';
import {
  Toggle,
  Text,
  Flex,
  Box,
  useMatchBreakpoints,
} from '@idexio/dev-idex-swap-uikit';
import styled from 'styled-components/macro';
import {
  ControlsState,
  ControlsAction,
  changeStakedOnly,
  changeQuery,
  changeSortOption,
  sortOptions,
} from './farmControlsReducer';
// import FarmTabButtons from './FarmTabButtons';
import SearchInput from '../SearchInput';
import Select from '../Select/Select';

const Heading = styled.h1`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: #ffffff;
`;

const MobileHeading = styled(Heading)`
  margin: 8px 8px 16px 0;
  width: 100%;
`;

const UnwrappedText = styled(Text)`
  white-space: nowrap;
`;

const ControlContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;

  justify-content: space-between;
  flex-direction: column;

  padding: 16px 0 0;

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;

  ${Text} {
    margin-left: 8px;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 0;

  ${({ theme }) => theme.mediaQueries.lg} {
    width: auto;
    padding: 8px 0;
    justify-content: flex-start;
  }
`;

const ViewControls = styled.div`
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 100%;

  > div {
    padding: 8px 0px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    justify-content: space-between;

    > div {
      padding: 0;
    }
  }
`;

const FarmControls: React.FC<{
  state: ControlsState;
  dispatch: React.Dispatch<ControlsAction>;
}> = ({ state, dispatch }) => {
  const handleChangeQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeQuery(event.target.value));
  };
  const { breakpointEqualOrLargerThan } = useMatchBreakpoints();
  const isDesktop = breakpointEqualOrLargerThan('xl');
  const isMobile = !isDesktop;

  return (
    <ControlContainer>
      {isMobile && <MobileHeading>Inactive Farms</MobileHeading>}
      <Flex alignItems="center" width={isMobile ? '100%' : 'initial'}>
        {isDesktop && (
          <Heading style={{ marginRight: 24, whiteSpace: 'nowrap' }}>
            Inactive Farms
          </Heading>
        )}
        <ViewControls>
          <ToggleWrapper>
            <Toggle
              checked={state.stakedOnly}
              onChange={() => dispatch(changeStakedOnly(!state.stakedOnly))}
              scale="md"
            />
            <UnwrappedText> Staked only</UnwrappedText>
          </ToggleWrapper>
          {/* <Box ml="3" mr={0}>
            <FarmTabButtons />
          </Box> */}
        </ViewControls>
      </Flex>

      <FilterContainer>
        <Select
          options={sortOptions.map(option => ({
            label: option,
            value: option,
          }))}
          onChange={option => dispatch(changeSortOption(option.value))}
          selected={state.sortOption || 'Default'}
          placeholder="- Sort by -"
          untouched={!state.sortOption}
        />
        <Box ml="3">
          <SearchInput onChange={handleChangeQuery} value={state.query} />
        </Box>
      </FilterContainer>
    </ControlContainer>
  );
};

export default FarmControls;
