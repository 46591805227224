import { Box } from '@idexio/dev-idex-swap-uikit';
import React from 'react';
import styled from 'styled-components/macro';

const Text = styled.div`
  opacity: 0.1;
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 16px;
  color: #a1a1a1;
  margin: 0px 10px;
`;

export default function FooterVersion() {
  return (
    <Box pt={10}>
      <Text>Farms version: {process.env.REACT_APP_RELEASE_VERSION}</Text>
    </Box>
  );
}
