import { useEffect } from 'react';
import { staticDispatch } from 'state';
import { fetchAndDispatchExchangeInfo } from 'state/exchangeInfo';
import { useExchangeInfo } from 'state/hooks';

/*
 * Due to Cors the api was forked and a proxy was created
 * @see https://github.com/pancakeswap/gatsby-pancake-api/commit/e811b67a43ccc41edd4a0fa1ee704b2f510aa0ba
 */
// export const baseUrl = 'https://api.pancakeswap.com/api/v1'

/* eslint-disable camelcase */
export interface ApiTvlResponse {
  updateAt: string;
  totalVolume24hUsd: number;
  totalValueLockedUsd: number;
  idexMarketCapUsd: number;
}

export const useFetchTotalStats = () => {
  useEffect(() => {
    staticDispatch(fetchAndDispatchExchangeInfo());
  }, []);
  const exchangeInfo = useExchangeInfo();
  return exchangeInfo;
};
