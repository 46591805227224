import React from 'react';
import { Button, useWalletModal } from '@idexio/dev-idex-swap-uikit';
import useAuth from 'hooks/useAuth';
import useI18n from 'hooks/useI18n';
import { useIsRestricted } from './RestrictedBanner';

const UnlockButton = props => {
  const isRestricted = useIsRestricted();
  const TranslateString = useI18n();
  const { login, logout } = useAuth();
  const { onPresentConnectModal } = useWalletModal(login, logout);

  return (
    <Button onClick={onPresentConnectModal} disabled={isRestricted} {...props}>
      {TranslateString(292, 'Unlock Wallet')}
    </Button>
  );
};

export default UnlockButton;
