// Set of helper functions to facilitate wallet setup

import config from 'config/constants';
import { NATIVE_ASSET_SYMBOL } from 'config/constants/environments';
import { getExplorerAddress } from 'utils';

/**
 * Prompt the user to add required a network on Metamask, or switch to network if the wallet is on a different network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
export const setupNetwork = async () => {
  const provider = (window as any).ethereum;
  if (!provider) {
    console.error(
      `Can't setup the ${config.chainNetwork} network on metamask because window.ethereum is undefined`
    );
    return false;
  }
  if (!provider.request) {
    console.error(
      `Can't setup the ${config.chainNetwork} network on metamask because window.ethereum.request is undefined`
    );
    return false;
  }
  if (document.hidden) {
    // eslint-disable-next-line no-console
    console.log('Do not triggering chain switch because Browser tab is hidden');
    return false;
  }
  try {
    await provider.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: `0x${config.chainId.toString(16)}`,
          chainName: config.chainNetwork,
          nativeCurrency: {
            name: NATIVE_ASSET_SYMBOL,
            symbol: NATIVE_ASSET_SYMBOL,
            decimals: 18,
          },
          rpcUrls: [config.chainRpcSoftwareWallet],
          blockExplorerUrls: [getExplorerAddress()],
        },
      ],
    });
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

/**
 * Prompt the user to add a custom token to metamask
 * @param tokenAddress
 * @param tokenSymbol
 * @param tokenDecimals
 * @param tokenImage
 * @returns {boolean} true if the token has been added, false otherwise
 */
export const registerToken = async (
  tokenAddress: string,
  tokenSymbol: string,
  tokenDecimals: number,
  tokenImage: string
) => {
  const windowEthereum = (window as WindowChain).ethereum;
  if (!windowEthereum?.request) {
    return;
  }
  await windowEthereum?.request({
    method: 'wallet_watchAsset',
    params: {
      type: 'ERC20',
      options: {
        address: tokenAddress,
        symbol: tokenSymbol,
        decimals: tokenDecimals,
        image: tokenImage,
      },
    },
  });
};
