import BigNumber from 'bignumber.js';
import React, { useCallback, useMemo, useState } from 'react';
import { Button, Modal, Link, Box } from '@idexio/dev-idex-swap-uikit';
import ModalActions from 'components/ModalActions';
import ModalInput from 'components/ModalInput';
import useI18n from 'hooks/useI18n';
import { getFullDisplayBalance } from 'utils/formatBalance';
import { useToast } from 'state/hooks';
import SpinningRoundedBorder from 'components/SpinningRoundedBorder/SpinningRoundedBorder';

interface DepositModalProps {
  max: BigNumber;
  onConfirm: (amount: string) => Promise<void>;
  onDismiss?: () => void;
  tokenName?: string;
  addLiquidityUrl?: string;
}

const DepositModal: React.FC<DepositModalProps> = ({
  max,
  onConfirm,
  onDismiss,
  tokenName = '',
  addLiquidityUrl,
}) => {
  const [value, setValue] = useState('');
  const [pendingTx, setPendingTx] = useState(false);
  const { toastError, toastSuccess } = useToast();
  const TranslateString = useI18n();
  const fullBalance = useMemo(() => getFullDisplayBalance(max), [max]);

  const fullBalanceNumber = useMemo(() => Number(fullBalance), [fullBalance]);
  const isOverAvailableBalance = useMemo(
    () => Number(value) > fullBalanceNumber,
    [fullBalanceNumber, value]
  );

  const handleChange = useCallback(
    (error: React.FormEvent<HTMLInputElement>) => {
      setValue(error.currentTarget.value);
    },
    [setValue]
  );

  const handleSelectMax = useCallback(() => {
    setValue(fullBalance);
  }, [fullBalance, setValue]);

  const handleDeposit = async () => {
    setPendingTx(true);
    try {
      await onConfirm(value);
      toastSuccess('LP tokens successfully added');
    } catch (error) {
      console.warn(error);
      toastError(error?.message);
    }
    setPendingTx(false);
    if (onDismiss) {
      onDismiss();
    }
  };

  return (
    <Modal
      title={TranslateString(1068, 'Stake LP tokens')}
      onDismiss={onDismiss}
    >
      <ModalInput
        value={value}
        onSelectMax={handleSelectMax}
        onChange={handleChange}
        max={fullBalanceNumber}
        symbol={tokenName}
        inputTitle={TranslateString(1070, 'Stake')}
      />
      <ModalActions>
        <Button variant="secondary" onClick={onDismiss} width="100%">
          {TranslateString(462, 'Cancel')}
        </Button>
        <Button
          width="100%"
          disabled={
            pendingTx ||
            fullBalance === '0' ||
            value === '' ||
            value === '0' ||
            isOverAvailableBalance
          }
          endIcon={
            pendingTx ? (
              <Box ml="1">
                <SpinningRoundedBorder inline size={24} />
              </Box>
            ) : undefined
          }
          onClick={handleDeposit}
        >
          {pendingTx
            ? TranslateString(488, 'Pending Confirmation')
            : TranslateString(464, 'Confirm')}
        </Button>
      </ModalActions>
      <Link
        py="3"
        href={addLiquidityUrl}
        style={{ alignSelf: 'center' }}
        color="primaryLight"
      >
        {TranslateString(999, 'Get')} {tokenName}
      </Link>
    </Modal>
  );
};

export default DepositModal;
