import { AbiItem } from 'web3-utils';
import { Interface } from '@ethersproject/abi';
import { getWeb3NoAccount } from 'utils/web3';
import MultiCallAbi from 'config/abi/Multicall.json';
import { getMulticallAddress } from 'utils/addressHelpers';

interface Call {
  address: string; // Address of the contract
  name: string; // Function name on the contract (example: balanceOf)
  params?: any[]; // Function params
}

/**
 * Multi contract call
 * @returns string, BigNumber, or array with mixed types (per contract)
 *  BigNumber is type of @ethersproject/bignumber
 */
async function multicall(abi: any[], calls: Call[]) {
  const web3 = getWeb3NoAccount();
  const multi = new web3.eth.Contract(
    MultiCallAbi as unknown as AbiItem,
    getMulticallAddress()
  );
  const itf = new Interface(abi);

  const calldata = calls.map(call => [
    call.address.toLowerCase(),
    itf.encodeFunctionData(call.name, call.params),
  ]);
  const { returnData } = await multi.methods.aggregate(calldata).call();
  return returnData.map((call, i) =>
    itf.decodeFunctionResult(calls[i].name, call)
  );
}

export default multicall;
