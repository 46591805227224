import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { Box, Button, Flex } from '@idexio/dev-idex-swap-uikit';
import BigNumber from 'bignumber.js';

import { BASE_ADD_LIQUIDITY_URL } from 'config';
import { readableLpName } from 'utils/addressHelpers';
import getLiquidityUrlPathParts from 'utils/getLiquidityUrlPathParts';
import { getExplorerAddress } from 'utils';
import UnlockButton from 'components/UnlockButton';
import { useApiPrices, useFarmUser } from 'state/hooks';
import { DEBUG_ENABLED } from 'config/constants/environments';
import SpinningRoundedBorder from 'components/SpinningRoundedBorder/SpinningRoundedBorder';
import CardHeading from './CardHeading';
import { FarmWithStakedValue } from './types.d';
import ApyButton from './ApyButton';
import MultiplierIcon from './MultiplierIcon';
import { useFarmApproval } from './CardActionsContainer';
import ColumnBox, { Label as ColumnBoxLabel } from './ColumnBox';
import RewardsInfoIcon from './RewardsInfoIcon';
import HarvestAction from './HarvestAction';
import StakeAction from './StakeAction';
import RewardsIcons from './RewardsIcons';

const Row = styled.div<{ highlighted: boolean; removed: boolean }>`
  align-self: baseline;
  background: ${({ theme, removed }) =>
    removed ? 'rgba(0, 0, 0, 0.5)' : theme.card.background};
  border: 1px solid
    ${({ highlighted }) => (highlighted ? '#B08BEF' : '#272727')};

  margin: 14px 0;

  display: grid;

  /* Mobile is one-column */
  grid-template-columns: 1fr;

  ${({ theme }) => theme.mediaQueries.xl} {
    /* Grid calculation https://docs.google.com/spreadsheets/d/1nzWW0dPTUfh2qHmEmAKACNFSqnrABsEWWQdwHyutZlw/edit#gid=0 */
    /* grid-template-columns: 2.4fr 1fr 1fr 1.1fr 3.4fr; */
    grid-template-columns: 2.2fr 1.1fr 1fr 1.1fr 3.4fr;
  }
`;

const StakedRewardsColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  ${({ theme }) => theme.mediaQueries.xl} {
    grid-template-columns: 1fr 1.55fr;
  }
`;

interface FarmCardProps {
  farm: FarmWithStakedValue;
  removed: boolean;
  // provider?: ProviderType;
  account?: string | null;
  isMobile: boolean;
}

const FarmRow: React.FC<FarmCardProps> = ({
  farm,
  removed,
  account,
  isMobile,
}) => {
  const totalValueFarmedUsd = !farm.totalLiqudityUsdBN
    ? '-'
    : farm.totalLiqudityUsdBN?.isNaN()
    ? '$0'
    : `$${farm.totalLiqudityUsdBN
        .toNumber()
        .toLocaleString(undefined, { maximumFractionDigits: 0 })}`;

  const lpTokensInFarmBN = useMemo(
    () =>
      farm.lpTokensInFarm ? new BigNumber(farm.lpTokensInFarm) : undefined,
    [farm.lpTokensInFarm]
  );

  const lpLabel = readableLpName(farm?.lpSymbol);
  // const earnLabel = farm.dual ? farm.dual.earnLabel : tokens.cake.symbol

  const liquidityUrlPathParts = getLiquidityUrlPathParts({
    baseSymbol: farm.token.symbol,
    quoteSymbol: farm.quoteToken.symbol,
  });
  const addLiquidityUrl = `${BASE_ADD_LIQUIDITY_URL}/${liquidityUrlPathParts}`;

  const { tokenBalance, stakedBalance } = useFarmUser(farm);

  const farmApy = farm?.apy;
  const apyFormatted = !farmApy
    ? ''
    : farmApy > 100
    ? farmApy?.toLocaleString('en-US', { maximumFractionDigits: 0 })
    : farmApy?.toLocaleString('en-US', { maximumFractionDigits: 2 });

  const { handleApprove, isApproved, requestedApproval } =
    useFarmApproval(farm);

  const apiPrices = useApiPrices();

  const { rewardToken1, rewardToken2 } = farm.farmContract;

  const rewardToken1Price = apiPrices?.[rewardToken1.address.toLowerCase()];
  const rewardToken2Price = rewardToken2
    ? apiPrices?.[rewardToken2.address.toLowerCase()]
    : undefined;

  const { earnings1, earnings2 } = farm.userData || {};

  const { earningsUsdTotal, earnings1Usd, earnings2Usd } = useMemo(() => {
    const earningsToken1Usd =
      earnings1 !== undefined && rewardToken1Price
        ? earnings1 * rewardToken1Price
        : undefined;
    const earningsToken2Usd = !rewardToken2
      ? false // Not available for current farm
      : earnings2 !== undefined && rewardToken2Price
      ? earnings2 * rewardToken2Price
      : undefined;
    return {
      earnings1Usd: earningsToken1Usd,
      earnings2Usd: earningsToken2Usd || undefined,
      earningsUsdTotal:
        earningsToken2Usd === false
          ? earningsToken1Usd // Single reward farm
          : earningsToken2Usd === undefined || earningsToken1Usd === undefined
          ? undefined // Error state, don't display balance1
          : earningsToken1Usd + earningsToken2Usd,
    };
  }, [
    earnings1,
    earnings2,
    rewardToken1Price,
    rewardToken2Price,
    rewardToken2,
  ]);

  const approveOrUnlock = !account ? (
    <UnlockButton width="100%" />
  ) : (
    <Button
      width="100%"
      disabled={requestedApproval || removed}
      endIcon={
        requestedApproval ? (
          <Box ml="1">
            <SpinningRoundedBorder inline size={24} />
          </Box>
        ) : undefined
      }
      onClick={handleApprove}
    >
      Approve Contract
    </Button>
  );

  return (
    <Row highlighted={farm.highlighted} removed={removed}>
      <ColumnBox isMobile={isMobile}>
        <CardHeading
          lpLabel={lpLabel}
          tokenASymbol={farm.token.symbol}
          tokenBSymbol={farm.quoteToken.symbol}
          removed={removed}
          chainScanAddress={`${getExplorerAddress()}/address/${farm.lpAddress}`}
          addLiquidityUrl={addLiquidityUrl}
        />
      </ColumnBox>

      <ColumnBox title="APR" isMobile={isMobile} middlePadding>
        {removed ? (
          '--'
        ) : (
          <div style={{ whiteSpace: 'nowrap' }}>
            <b>
              {farmApy === 0 ? (
                '0%'
              ) : !farmApy ? (
                '' // Loading
              ) : farmApy > 1_000_000 ? (
                <>&infin;%</>
              ) : (
                <>{apyFormatted}%</>
              )}{' '}
            </b>
            {!!farmApy && (
              <ApyButton
                lpLabel={lpLabel}
                addLiquidityUrl={addLiquidityUrl}
                apy={farmApy}
              />
            )}
          </div>
        )}
      </ColumnBox>
      <ColumnBox
        isMobile={isMobile}
        middlePadding
        title={
          <>
            MULTIPLIER{' '}
            <MultiplierIcon multiReward={!!farm.farmContract.rewardToken2} />
          </>
        }
      >
        {farm.farmContract.rewardToken2
          ? '--'
          : farm.multiplier?.toLowerCase() || <>&nbsp;</>}
      </ColumnBox>
      {/* <Text color="primaryAlt" bold fontSize="12px">
              IDEX earned
            </Text>
            <Text fontSize="4" color="primaryLight" mt="2">
              {rawEarningsBalance !== undefined ? (
                rawEarningsBalance.toLocaleString()
              ) : (
                <>--</>
              )}
              <CardBusdValue value={earningsUsd || 0} /> */}
      <ColumnBox title="LIQUIDITY" middlePadding isMobile={isMobile}>
        {totalValueFarmedUsd}{' '}
      </ColumnBox>

      {!account || isApproved === false ? (
        <ColumnBox
          title={isMobile ? undefined : 'REWARDS'}
          isMobile={isMobile}
          isLast
        >
          {isMobile ? (
            <Flex justifyContent="space-between" mt="3">
              <Box pb="3">
                <ColumnBoxLabel mb="1">REWARDS</ColumnBoxLabel>
                <RewardsIcons farm={farm} size="md" />
              </Box>
              <Flex maxWidth="300px" width="65%" mt="1">
                {approveOrUnlock}
              </Flex>
            </Flex>
          ) : (
            <Flex width="calc(100% - 24px)" py={0}>
              <RewardsIcons farm={farm} size="md" enforceTwoIconWidth mr="1" />
              {approveOrUnlock}
            </Flex>
          )}
        </ColumnBox>
      ) : !isApproved ? (
        <ColumnBox title="REWARDS" middlePadding isMobile={isMobile} isLast>
          {/* Loading */}
        </ColumnBox>
      ) : !tokenBalance || !stakedBalance ? null /* Loading */ : (
        <StakedRewardsColumns>
          <ColumnBox
            title="STAKED"
            middlePadding={stakedBalance.gt(0)}
            isMobile={isMobile}
          >
            <StakeAction
              farm={farm}
              tokenBalance={tokenBalance}
              stakedBalance={stakedBalance}
              tokenName={readableLpName(farm.lpSymbol)}
              addLiquidityUrl={addLiquidityUrl}
              removed={removed}
              isMobile={isMobile}
              farmLpTokenInSupply={lpTokensInFarmBN}
              farmLiquidityUsd={farm.totalLiqudityUsdBN}
            />
          </ColumnBox>
          <ColumnBox
            isMobile={isMobile}
            isLast
            title={
              isMobile ? undefined : (
                <>
                  REWARDS{' '}
                  <RewardsInfoIcon
                    farmContract={farm.farmContract}
                    earnings1={earnings1}
                    earnings1Usd={earnings1Usd}
                    earnings2={earnings2}
                    earnings2Usd={earnings2Usd}
                    isMobile={isMobile}
                  />
                </>
              )
            }
          >
            <HarvestAction
              farm={farm}
              isMobile={isMobile}
              earningsUsdTotal={earningsUsdTotal}
              earnings1Usd={earnings1Usd}
              earnings2Usd={earnings2Usd}
              earnings1={earnings1}
              earnings2={earnings2}
            />

            {DEBUG_ENABLED && (
              <div style={{ color: 'yellow' }}>
                <br />
                {farm.farmContract.rewardToken1.symbol} price: $
                {rewardToken1Price}
                {farm.farmContract.rewardToken2?.symbol && (
                  <>
                    <br />
                    {farm.farmContract.rewardToken2?.symbol} price: $
                    {rewardToken2Price}
                  </>
                )}
              </div>
            )}
          </ColumnBox>
        </StakedRewardsColumns>
      )}
    </Row>
  );
};

export default FarmRow;
