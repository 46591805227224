import React, { useMemo } from 'react';
import BigNumber from 'bignumber.js';
import {
  Button,
  Box,
  Flex,
  useModal,
  Tooltip,
  Text,
} from '@idexio/dev-idex-swap-uikit';

import useStake from 'hooks/useStake';
import useUnstake from 'hooks/useUnstake';
import { getBalanceNumber } from 'utils/formatBalance';
import { FarmConfig } from 'config/constants/types';
import { LP_DECIMALS } from 'config/constants/farms';
import DepositModal from '../DepositModal';
import WithdrawModal from '../WithdrawModal';

function StakeUnstakeButton({
  onClick,
  disabled,
  title,
  children,
}: {
  disabled: boolean;
  onClick: () => void;
  title: string;
  children: React.ReactNode;
}) {
  return (
    <Button
      variant="secondary"
      disabled={disabled}
      onClick={onClick}
      mr="6px"
      p="0"
      pb="2px"
      pl="1px"
      title={title}
      style={{ fontSize: 24, borderColor: 'var(--primaryLight)' }}
      height={25}
      width={25}
    >
      {children}
    </Button>
  );
}

interface FarmCardActionsProps {
  farm: FarmConfig;
  stakedBalance: BigNumber;
  tokenBalance: BigNumber;
  tokenName?: string;
  addLiquidityUrl?: string;
  removed: boolean;
  isMobile: boolean;
  farmLpTokenInSupply: BigNumber | undefined;
  farmLiquidityUsd: BigNumber | undefined;
}

const StakeAction: React.FC<FarmCardActionsProps> = ({
  farm,
  stakedBalance,
  tokenBalance,
  tokenName,
  addLiquidityUrl,
  removed,
  isMobile,
  farmLpTokenInSupply,
  farmLiquidityUsd,
}) => {
  const { onStake } = useStake(farm);
  const { onUnstake } = useUnstake(farm);
  const rawStakedBalance = useMemo(
    () =>
      stakedBalance ? getBalanceNumber(stakedBalance, LP_DECIMALS) : undefined,
    [stakedBalance]
  );
  // const displayBalance = rawStakedBalance?.toLocaleString();

  const [onPresentDeposit] = useModal(
    <DepositModal
      max={tokenBalance || new BigNumber(0)}
      onConfirm={onStake}
      tokenName={tokenName}
      addLiquidityUrl={addLiquidityUrl}
    />
  );
  const [onPresentWithdraw] = useModal(
    <WithdrawModal
      max={stakedBalance || new BigNumber(0)}
      onConfirm={onUnstake}
      tokenName={tokenName}
    />
  );

  const removeStakeNotPossible = false;
  const addStakeNotPossible = removed;
  // !tokenBalance || tokenBalance.isLessThanOrEqualTo(0);

  const stakedBalanceUsd = useMemo(
    () =>
      farmLiquidityUsd && farmLpTokenInSupply && rawStakedBalance
        ? new BigNumber(rawStakedBalance)
            .dividedBy(farmLpTokenInSupply)
            .multipliedBy(farmLiquidityUsd)
            .toNumber()
        : undefined,
    [rawStakedBalance, farmLpTokenInSupply, farmLiquidityUsd]
  );

  return rawStakedBalance === 0 ? (
    <Button
      width={isMobile ? 'initial' : 'calc(100% - 24px)'}
      my={isMobile ? '2' : 0}
      onClick={onPresentDeposit}
      disabled={addStakeNotPossible}
    >
      Stake LP
    </Button>
  ) : (
    <Flex alignItems="center">
      <Box
        mr="3"
        color={rawStakedBalance && rawStakedBalance > 0 ? 'text' : 'textSubtle'}
      >
        {stakedBalanceUsd !== undefined && rawStakedBalance !== undefined && (
          <Tooltip
            content={
              <Text fontSize="1">{rawStakedBalance?.toFixed(3)} LP tokens</Text>
            }
          >
            $
            {stakedBalanceUsd.toLocaleString(undefined, {
              maximumFractionDigits: 0,
            })}
          </Tooltip>
        )}
      </Box>
      <Box style={{ whiteSpace: 'nowrap' }}>
        <StakeUnstakeButton
          disabled={removeStakeNotPossible}
          onClick={onPresentWithdraw}
          title="Unstake LP tokens"
        >
          -
        </StakeUnstakeButton>
        <StakeUnstakeButton
          onClick={onPresentDeposit}
          disabled={addStakeNotPossible}
          title="Stake LP tokens"
        >
          +
        </StakeUnstakeButton>
      </Box>
    </Flex>
  );
};

export default StakeAction;
