import { devTokens, stagingTokens, productionTokens } from './tokens';
import { FarmContract } from './types';

type FarmContractMap = {
  [key: string]: FarmContract;
};

export const productionFarmContracts: FarmContractMap = {
  original: {
    contractAbi: 'singleReward',
    contractAddress: '0xb9c951c85c8646dafcdc0ad99d326c621abbadce',
    rewardToken1: productionTokens.idex,
  },
  multiRewardIdexMatic: {
    contractAbi: 'multiReward',
    contractAddress: '0xB17c0F6B1c34cD3ee989c0dF5b560de909f89f95',
    rewardToken1: productionTokens.idex,
    rewardToken2: productionTokens.matic,
  },
  multiRewardIdexAlgb: {
    contractAbi: 'multiReward',
    contractAddress: '0x2617Fc52ba3b6fA0610d5ae78E4f6c891160d57b',
    rewardToken1: productionTokens.idex,
    rewardToken2: productionTokens.algb,
  },
};

export const stagingFarmContracts: FarmContractMap = {
  original: {
    contractAbi: 'singleReward',
    contractAddress: '0xf25f154635dbe7fe7C1e98B95eb199aF18f17CC4',
    rewardToken1: stagingTokens.idex,
  },
  multiReward1: {
    contractAbi: 'multiReward',
    // Initial with USDC 0xa04c8509D85D47616e463227703b381500c2F308 decimals issue
    contractAddress: '0xac666c4eb57ee70ba22149380dea21e12f5db468',
    rewardToken1: stagingTokens.idex,
    rewardToken2: stagingTokens.dil,
  },
  multiReward2: {
    contractAbi: 'multiReward',
    contractAddress: '0xE4EBf4e08CEbD2a3b0d244015Bb31B81Eb5e0Be7',
    rewardToken1: stagingTokens.idex,
    rewardToken2: stagingTokens.pip,
  },
  multiReward3: {
    contractAbi: 'multiReward',
    contractAddress: '0x84c9481f6a4a3aa33a1d4d7fd59216959e8e9bfe',
    rewardToken1: stagingTokens.idex,
    rewardToken2: stagingTokens.matic,
  },
};

export const devFarmContracts: FarmContractMap = {
  original: {
    contractAbi: 'singleReward',
    contractAddress: '0x010ccef37A35CD0745AF246D3ad8517Dd707C053',
    rewardToken1: devTokens.idex,
  },
};
