/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchInternalStats } from 'api';

interface TokenStats {
  liquidityProviderFees24hUsdMap: {
    [market: string]: number | undefined;
  };
}

interface TokenStatsApiThunk {
  // updated_at: string;
  data: TokenStats;
}

interface PriceState {
  isLoading: boolean;
  data: TokenStats | null;
}

const initialState: PriceState = {
  isLoading: false,
  data: null,
};

export const fetchTokenStats = createAsyncThunk<TokenStatsApiThunk, void>(
  'tokenStats/fetch',
  async () => {
    const { liquidityProviderFees24hUsd } = await fetchInternalStats();
    const liquidityProviderFees24hUsdMap = Object.entries(
      liquidityProviderFees24hUsd
    ).reduce((map, item) => {
      // Change string to number to faster render calculations
      map[item[0]] = Number(item[1]);
      return map;
    }, {} as { [key: string]: number });
    return {
      data: {
        liquidityProviderFees24hUsdMap,
      },
    };
  }
);

export const tokenStatsSlice = createSlice({
  name: 'tokenStats',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchTokenStats.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchTokenStats.fulfilled,
      (state, action: PayloadAction<TokenStatsApiThunk>) => {
        state.isLoading = false;
        state.data = action.payload.data;
      }
    );
  },
});

export default tokenStatsSlice.reducer;
