import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';
import { Flex, useMatchBreakpoints } from '@idexio/dev-idex-swap-uikit';

import config from 'config/constants';
import idexLogo from './IDEX-logo-white.svg';
import idexIcon from './IDEX-icon-white.svg';

const Wrapper = styled.div`
  background: #0e0e1a;
  height: 70px;
  border-bottom: 1px solid #333333;
  padding-left: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.textSubtle};
  padding: 8px;
  &:hover {
    text-decoration: underline;
  }
`;

const Logo = styled.img`
  max-height: 100%;
  max-width: 110px;
`;

export default function Header({
  afterMenuItem,
}: {
  afterMenuItem: ReactNode;
}): React.ReactElement {
  const { exchangeUrl } = config;
  const { breakpointEqualOrSmallerThan } = useMatchBreakpoints();
  const isMobile = breakpointEqualOrSmallerThan('sm');
  return (
    <Wrapper>
      <a href={exchangeUrl} target="_self">
        {isMobile ? (
          <Logo src={idexIcon} alt="IDEX" />
        ) : (
          <Logo src={idexLogo} alt="IDEX" width="110" />
        )}
      </a>
      <Flex pr="2" alignItems="center">
        <StyledLink href={exchangeUrl} target="_self">
          Exchange
        </StyledLink>
        <StyledLink
          href={`${exchangeUrl}/liquidity`}
          target="_self"
          style={{ marginRight: 8 }}
        >
          Liquidity
        </StyledLink>
        {afterMenuItem}
      </Flex>
    </Wrapper>
  );
}
