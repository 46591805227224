import React from 'react';
import CardValue, { CardValueProps } from './CardValue';

const CardBusdValue: React.FC<CardValueProps> = props => {
  return (
    <CardValue
      fontSize="14px"
      lineHeight="1.1"
      color="textSubtle"
      prefix="~$"
      bold={false}
      decimals={2}
      {...props}
    />
  );
};

export const CardBusdValue2: React.FC<CardValueProps> = props => {
  return (
    <CardValue
      fontSize="20px"
      bold={false}
      prefix="$"
      decimals={2}
      // eslint-disable-next-line react/destructuring-assignment
      color={props.value > 0 ? 'success' : 'textSubtle'}
      {...props}
    />
  );
};

export default CardBusdValue;
