import { useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';

import { useAppDispatch } from 'state';
import { fetchFarmUserDataAsync } from 'state/farms';
import { harvest } from 'utils/callHelpers';
import { FarmConfig } from 'config/constants/types';
import { getMasterchefInstance, useMasterchef } from './useContract';
import useWeb3 from './useWeb3';

export const useHarvest = (farmConfig: FarmConfig) => {
  const dispatch = useAppDispatch();
  const { account } = useWeb3React();
  const masterChefContract = useMasterchef(farmConfig.farmContract);

  const farmPid = farmConfig.pid;

  const handleHarvest = useCallback(async () => {
    if (typeof farmPid !== 'number') {
      throw new Error('Missing farm pid');
    }
    // const response = await getHarvestFundsInContract(masterChefContract);
    // if (!response) {
    //   throw new Error('Farms is underfunded. Please contact support.');
    // }
    const txHash = await harvest(masterChefContract, farmPid, account);
    // eslint-disable-next-line no-console
    console.log('HARVEST TX: ', txHash);
    dispatch(fetchFarmUserDataAsync(account));
    return txHash;
  }, [account, dispatch, farmPid, masterChefContract]);

  return { onReward: handleHarvest };
};

export const useAllHarvest = (farms: FarmConfig[]) => {
  const web3 = useWeb3();
  const { account } = useWeb3React();

  const handleHarvest = useCallback(async () => {
    const harvestPromises = farms.reduce((accum, farm) => {
      const masterChefContract = getMasterchefInstance(web3, farm.farmContract);
      return [...accum, harvest(masterChefContract, farm.pid, account)];
    }, [] as Promise<unknown>[]);

    return Promise.all(harvestPromises);
  }, [web3, account, farms]);

  return { onReward: handleHarvest };
};
