import React, { ReactNode } from 'react';
import { Box, Flex, Text, Tooltip } from '@idexio/dev-idex-swap-uikit';
import { useWeb3React } from '@web3-react/core';
import CardBusdValue from 'views/Farms/components/CardBusdValue';
import { RewardIcon } from '../FarmRow/RewardsIcons';

const HarvestAllBalance = ({
  totalEarningsUsd,
  totalEarningsTokenSymbols,
  earningsModalContent,
}: {
  totalEarningsUsd: number | undefined;
  totalEarningsTokenSymbols: string[];
  earningsModalContent: ReactNode;
}) => {
  // const TranslateString = useI18n();
  const { account } = useWeb3React();

  if (!account) {
    return null;
    //   <Text color="textDisabled" style={{ lineHeight: '76px' }}>
    //     {TranslateString(298, 'Locked')}
    //   </Text>
    // );
  }
  return (
    <Flex alignItems="baseline">
      {totalEarningsUsd === undefined ? (
        <Text lineHeight="1.5" fontSize="24px">
          &nbsp;
        </Text>
      ) : (
        <Tooltip content={earningsModalContent}>
          <Flex alignItems="center">
            <CardBusdValue
              value={totalEarningsUsd}
              prefix="$"
              lineHeight="1.5"
              fontSize="24px"
              color="text"
              bold={false}
            />
            <Box px="1" />
            {totalEarningsTokenSymbols.map(symbol => (
              <RewardIcon key={symbol} size="sm" symbol={symbol} />
            ))}
          </Flex>
        </Tooltip>
      )}
      {/* <div>&nbsp;</div>
      {!idexPriceUsd.eq(0) && <CardBusdValue value={earningsUsd} />} */}
    </Flex>
  );
};

export default HarvestAllBalance;
