import { useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';

import { staticDispatch } from 'state';
import { fetchFarmUserDataAsync } from 'state/farms';
import { stake } from 'utils/callHelpers';
import { FarmConfig } from 'config/constants/types';
import { useMasterchef } from './useContract';

const useStake = (farmConfig: FarmConfig) => {
  const { account } = useWeb3React();
  const masterChefContract = useMasterchef(farmConfig.farmContract);

  const { pid } = farmConfig;

  const handleStake = useCallback(
    async (amount: string) => {
      const txHash = await stake(masterChefContract, pid, amount, account);
      staticDispatch(fetchFarmUserDataAsync(account));
      console.info(txHash);
    },
    [account, masterChefContract, pid]
  );

  return { onStake: handleStake };
};

export default useStake;
