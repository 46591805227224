import BigNumber from 'bignumber.js';

import { BLOCKS_PER_YEAR } from 'config';
import { DEBUG_ENABLED } from 'config/constants/environments';

/**
 * Get farm APY value in %
 * @param poolWeight allocationPoint / totalAllocationPoint
 * @param idexPriceUsd IDEX price in USD
 * @param poolLiquidityUsd Total pool liquidity in USD
 * @returns
 */
export const getFarmApy = ({
  poolWeight,
  rewardToken1PriceUsd,
  rewardToken2PriceUsd,
  reward1PerBlock,
  reward2PerBlock,
  poolLiquidityUsd,
  liquidityProviderFees24hUsd,
}: {
  poolWeight: BigNumber;
  rewardToken1PriceUsd: number;
  rewardToken2PriceUsd: number | undefined | null;
  reward1PerBlock: number;
  reward2PerBlock: number | undefined;
  poolLiquidityUsd: BigNumber;
  liquidityProviderFees24hUsd: number | undefined;
}): number | null => {
  if (!reward1PerBlock) {
    return null;
  }
  const yearlyToken1RewardAllocation = new BigNumber(reward1PerBlock)
    .times(BLOCKS_PER_YEAR)
    .times(poolWeight);
  const yearlyToken2RewardAllocation = new BigNumber(reward2PerBlock || 0)
    .times(BLOCKS_PER_YEAR)
    .times(poolWeight);

  const apy = yearlyToken1RewardAllocation
    .times(rewardToken1PriceUsd)
    .plus(yearlyToken2RewardAllocation.times(rewardToken2PriceUsd || 0))
    // Increase earned by estimation of LP fees
    .plus((liquidityProviderFees24hUsd || 0) * 365)
    .div(poolLiquidityUsd)
    .times(100);

  if (DEBUG_ENABLED) {
    // eslint-disable-next-line no-console
    console.log({
      yearlyToken1RewardAllocation: yearlyToken1RewardAllocation.toString(),
      yearlyToken2RewardAllocation: yearlyToken2RewardAllocation.toString(),
      apy: apy.toString(),
    });
  }

  return apy.isNaN() || !apy.isFinite() ? null : apy.toNumber();
};

export default null;
