import { addGeoblockedCountry } from 'state/restricted';
import type { AppDispatch } from 'state';

let geoRestrictedTriggered = false;

// We have to set this from store itself,
// Otherwise we hit cyclic dependency error
let staticDispatch: AppDispatch | undefined;

export function setStaticDispatch(dispatch: AppDispatch) {
  staticDispatch = dispatch;
}

function checkGeoHeaders(headers: Headers) {
  if (geoRestrictedTriggered) {
    // Trigger only once per session
    return;
  }
  /**
   * Renders to either 'false' or the restricted region in the case the user is
   * associated with a restricted region based on their IP address.  These restrictions
   * generally are more specific than the `geoHeader` value which is largely on a country
   * basis.  For example, New York State, Washington State, Crimea.
   */
  const geoRestrictionHeader =
    headers?.['idex-geo-restricted'] ||
    headers?.get?.('idex-geo-restricted') ||
    'false';
  const isGeoRestricted = geoRestrictionHeader !== 'false';

  if (isGeoRestricted) {
    console.warn(`GeoRestricted: ${geoRestrictionHeader}`);
    setTimeout(() => {
      if (staticDispatch) {
        staticDispatch(addGeoblockedCountry(geoRestrictionHeader));
      } else {
        // Error state, not yet initialized for some reason
        geoRestrictedTriggered = false;
      }
    });

    geoRestrictedTriggered = true;
  }
}

export function handleGeoResponse(response: Response) {
  if (response.status === 200 && response.headers) {
    checkGeoHeaders(response.headers);
  }
  return response;
}

export function patchClientToIdexInternal(client: {
  axios: any;
  autoCreateHmacHeader: boolean;
}) {
  // eslint-disable-next-line no-param-reassign
  client.axios.defaults.headers.common[
    'IDEX-Web-Client'
  ] = `farms-${process.env.REACT_APP_RELEASE_VERSION}`;
  // client.autoCreateHmacHeader = false;
  // use session cookie via `withCredentials` when available
  // client.axios.defaults.withCredentials = true;

  client.axios.interceptors.response.use(
    async response => {
      try {
        if (response.status === 200) {
          checkGeoHeaders(response.headers);
        }
        if (response.status === 204) {
          return response;
        }
      } catch (err) {
        console.error(`[API PARSE ERROR, url=${response?.config?.url}]`, err);
        throw err;
      }

      // if (
      //   // response.status >= 400 ||
      //   (response?.data?.code && response?.data?.message)
      // ) {
      //   await handleErrorResponse(response, response.data);
      // }

      return response;
    }
    // (error: AxiosError) => {
    //   return handleErrorResponse(
    //     error?.response,
    //     error?.response?.data,
    //     error?.stack
    //   );
    // }
  );
}
