import React from 'react';
import { Box, Flex } from '@idexio/dev-idex-swap-uikit';

import { getCoinIcon } from 'config/coinIcons';
import { FarmConfig } from 'config/constants/types';

export function RewardIcon({
  symbol,
  size,
  marginRight,
}: {
  symbol: string;
  size: 'sm' | 'md';
  marginRight?: number;
}) {
  const marginRightPx = marginRight ?? (size === 'sm' ? 4 : 24);
  return (
    <img
      src={getCoinIcon(symbol)}
      alt={symbol}
      title={symbol}
      style={
        size === 'sm'
          ? {
              height: 18,
              position: 'relative',
              top: 2,
              marginRight: marginRightPx,
            }
          : {
              height: 28,
              position: 'relative',
              top: 6,
              marginRight: marginRightPx,
            }
      }
    />
  );
}
interface RewardsIconsProps {
  farm: FarmConfig;
  size?: 'sm' | 'md';
  enforceTwoIconWidth?: boolean;
  mr?: string;
}

const RewardsIcons: React.FC<RewardsIconsProps> = ({
  farm,
  size = 'sm',
  enforceTwoIconWidth = false,
  mr,
}) => {
  const { rewardToken1, rewardToken2 } = farm.farmContract;
  return (
    <Flex alignItems="baseline" mr={mr}>
      <RewardIcon
        symbol={rewardToken1.symbol}
        size={size}
        marginRight={rewardToken2 ? 0 : undefined}
      />
      {rewardToken2 ? (
        <RewardIcon symbol={rewardToken2.symbol} size={size} />
      ) : enforceTwoIconWidth ? (
        <Box width={28} />
      ) : null}
    </Flex>
  );
};

export default RewardsIcons;
