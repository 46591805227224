import {
  devFarmContracts,
  productionFarmContracts,
  stagingFarmContracts,
} from './farmsContracts';
import { devTokens, stagingTokens, productionTokens } from './tokens';
import { FarmConfig } from './types';

export const LP_DECIMALS = 18;

const addLpSymbolToConfig = (
  farm: Omit<FarmConfig, 'lpSymbol'>
): FarmConfig => ({
  ...farm,
  lpSymbol: `ILP-${farm.token.symbol}-${farm.quoteToken.symbol}`,
});

// https://github.com/pancakeswap/pancake-farm/blob/master/contracts/MasterChef.sol
// https://github.com/idexio/pancake-farm/blob/dev/contracts/MasterChef.sol

export const productionFarms: FarmConfig[] = [
  // Multi reward farms
  {
    pid: 0,
    lpAddress: '0xe587C332eDaB74a4E443C6a6a88cBCB727125b39',
    token: productionTokens.idex,
    quoteToken: productionTokens.eth,
    highlighted: false,
    farmContract: productionFarmContracts.multiRewardIdexMatic,
    inactiveFlag: true,
    force0xMultiplier: true,
  },
  {
    pid: 1,
    lpAddress: '0xfcD9e1624938d8C8dA568DD6C75E3396Ee762301',
    token: productionTokens.idex,
    quoteToken: productionTokens.usdc,
    highlighted: true,
    farmContract: productionFarmContracts.multiRewardIdexMatic,
  },
  {
    pid: 2,
    lpAddress: '0x6680ed0B92bd226F50aB704A0226391ADd943E55',
    token: productionTokens.eth,
    quoteToken: productionTokens.usdc,
    highlighted: false,
    farmContract: productionFarmContracts.multiRewardIdexMatic,
    inactiveFlag: true,
    force0xMultiplier: true,
  },
  {
    pid: 3,
    lpAddress: '0x14ecE8839BCB4946Cc99C3a01e69575969039c1F',
    token: productionTokens.matic,
    quoteToken: productionTokens.eth,
    highlighted: false,
    farmContract: productionFarmContracts.multiRewardIdexMatic,
    inactiveFlag: true,
    force0xMultiplier: true,
  },
  {
    pid: 0,
    lpAddress: '0x2E541d502390a8c77913E72f1404AF162Dd68d5E',
    token: productionTokens.algb,
    quoteToken: productionTokens.usdc,
    highlighted: false,
    // orderPriority: 1,
    farmContract: productionFarmContracts.multiRewardIdexAlgb,
    inactiveFlag: true,
    force0xMultiplier: true,
  },
  // // ADD_NEW_DUAL_PROD_PLACEHOLDER

  // Single reward farms
  {
    pid: 0,
    lpAddress: '0x6680ed0B92bd226F50aB704A0226391ADd943E55',
    token: productionTokens.eth,
    quoteToken: productionTokens.usdc,
    highlighted: false,
    orderPriority: 3,
    farmContract: productionFarmContracts.original,
    inactiveFlag: true,
    force0xMultiplier: true,
  },
  {
    pid: 1,
    lpAddress: '0x0494dBe60fb0a3174a8065bB68010cC78B09E7ec',
    token: productionTokens.usdt,
    quoteToken: productionTokens.usdc,
    highlighted: false,
    orderPriority: 9,
    farmContract: productionFarmContracts.original,
    inactiveFlag: true,
    force0xMultiplier: true,
  },
  {
    pid: 2,
    lpAddress: '0xe02624080123F265BbE3BfEA60d6Fe66dAF0e810',
    token: productionTokens.dai,
    quoteToken: productionTokens.usdc,
    highlighted: false,
    orderPriority: 10,
    farmContract: productionFarmContracts.original,
    inactiveFlag: true,
    force0xMultiplier: true,
  },
  {
    pid: 3,
    lpAddress: '0xe587C332eDaB74a4E443C6a6a88cBCB727125b39',
    token: productionTokens.idex,
    quoteToken: productionTokens.eth,
    highlighted: false,
    orderPriority: 1,
    farmContract: productionFarmContracts.original,
    inactiveFlag: true,
    force0xMultiplier: true,
  },
  {
    excluded: true, // REMOVED
    pid: 4,
    lpAddress: '0x403701ffa34ab9a0e32530021D77CF11c28f8c74',
    token: productionTokens.eth,
    quoteToken: productionTokens.eth,
    highlighted: false,
    orderPriority: 9999,
    farmContract: productionFarmContracts.original,
  },
  {
    pid: 5,
    lpAddress: '0x50C466bB40077b690e47cF3105417427EDC07429',
    token: productionTokens.aave,
    quoteToken: productionTokens.eth,
    highlighted: false,
    orderPriority: 15,
    farmContract: productionFarmContracts.original,
    inactiveFlag: true,
    force0xMultiplier: true,
  },
  {
    pid: 6,
    lpAddress: '0xe963527b3a31B2108c2dd312c08D8518a659Eb9B',
    token: productionTokens.crv,
    quoteToken: productionTokens.eth,
    highlighted: false,
    orderPriority: 14,
    farmContract: productionFarmContracts.original,
    inactiveFlag: true,
    force0xMultiplier: true,
  },
  {
    pid: 7,
    lpAddress: '0x14ecE8839BCB4946Cc99C3a01e69575969039c1F',
    token: productionTokens.matic,
    quoteToken: productionTokens.eth,
    highlighted: false,
    orderPriority: 11,
    farmContract: productionFarmContracts.original,
    inactiveFlag: true,
    force0xMultiplier: true,
  },
  {
    pid: 8,
    lpAddress: '0x0F1052494226586142A91a991c694428e99CCD93',
    token: productionTokens.cvx,
    quoteToken: productionTokens.eth,
    highlighted: false,
    orderPriority: 16,
    farmContract: productionFarmContracts.original,
    inactiveFlag: true,
    force0xMultiplier: true,
  },
  {
    excluded: true, // REMOVED
    pid: 9,
    lpAddress: '0xbf7Cf9b100d530AE08a68A278d26eCBB1bD74cD7',
    token: productionTokens.eth,
    quoteToken: productionTokens.eth,
    highlighted: false,
    orderPriority: 9999,
    farmContract: productionFarmContracts.original,
  },
  {
    pid: 10,
    lpAddress: '0x2e1B98f7fdc85800da80F9478789DAEff538fbD4',
    token: productionTokens.mana,
    quoteToken: productionTokens.eth,
    highlighted: false,
    orderPriority: 13,
    farmContract: productionFarmContracts.original,
    inactiveFlag: true,
    force0xMultiplier: true,
  },
  {
    excluded: true, // REMOVED
    pid: 11,
    lpAddress: '0x69A33D5080eB70595bbFE8959ED97A752C68893C',
    token: productionTokens.eth,
    quoteToken: productionTokens.eth,
    highlighted: false,
    orderPriority: 5,
    farmContract: productionFarmContracts.original,
  },
  {
    pid: 12,
    lpAddress: '0xb83f88072ff755ce60A457569F6aB5E4A6b14f8B',
    token: productionTokens.link,
    quoteToken: productionTokens.eth,
    highlighted: false,
    orderPriority: 12,
    farmContract: productionFarmContracts.original,
    inactiveFlag: true,
    force0xMultiplier: true,
  },
  {
    pid: 13,
    lpAddress: '0x4e8fB9aDd87d936115Ff0b300df49bB4D192545A',
    token: productionTokens.matic,
    quoteToken: productionTokens.usdc,
    highlighted: false,
    orderPriority: 7,
    farmContract: productionFarmContracts.original,
    inactiveFlag: true,
    force0xMultiplier: true,
  },
  {
    pid: 14,
    lpAddress: '0x5CFdDC9f2df0281C105daEC9943eC06aCF684193',
    token: productionTokens.wbtc,
    quoteToken: productionTokens.usdc,
    highlighted: false,
    orderPriority: 5,
    farmContract: productionFarmContracts.original,
    inactiveFlag: true,
    force0xMultiplier: true,
  },
  {
    pid: 15,
    lpAddress: '0xfcD9e1624938d8C8dA568DD6C75E3396Ee762301',
    token: productionTokens.idex,
    quoteToken: productionTokens.usdc,
    highlighted: false,
    orderPriority: 2,
    farmContract: productionFarmContracts.original,
    inactiveFlag: true,
    force0xMultiplier: true,
  },
  {
    pid: 16,
    lpAddress: '0x76400d4D0F43fB30755331D00E41fC19EfcaE25c',
    token: productionTokens.ghst,
    quoteToken: productionTokens.usdc,
    highlighted: false,
    orderPriority: 8,
    farmContract: productionFarmContracts.original,
    inactiveFlag: true,
    force0xMultiplier: true,
  },
  {
    pid: 17,
    lpAddress: '0x4F1Bd73BAcBd1908898217F17B486Ae082F496Be',
    token: productionTokens.mai,
    quoteToken: productionTokens.usdc,
    highlighted: false,
    orderPriority: 6,
    farmContract: productionFarmContracts.original,
    inactiveFlag: true,
    force0xMultiplier: true,
  },
  {
    pid: 18,
    lpAddress: '0xA544aC859E4D5F3ab93a0Cb93d845cC526a6862E',
    token: productionTokens.syn,
    quoteToken: productionTokens.eth,
    highlighted: false,
    orderPriority: 4,
    farmContract: productionFarmContracts.original,
    inactiveFlag: true,
    force0xMultiplier: true,
  },
  {
    pid: 19,
    lpAddress: '0xeeb67BCBf53B47bC51D7D2b435b4169c47578b21',
    token: productionTokens.sx,
    quoteToken: productionTokens.usdc,
    highlighted: false,
    orderPriority: 3.9,
    farmContract: productionFarmContracts.original,
    inactiveFlag: true,
    force0xMultiplier: true,
  },
  {
    pid: 20,
    lpAddress: '0x792bA409eEC6aE0bda9Bab47d413b3175ebD46fb',
    token: productionTokens.quick,
    quoteToken: productionTokens.eth,
    highlighted: false,
    orderPriority: 3.7,
    farmContract: productionFarmContracts.original,
    inactiveFlag: true,
    force0xMultiplier: true,
  },
  {
    pid: 21,
    lpAddress: '0xC038804F415E891aD1660E4165225568b1b02202',
    token: productionTokens.bifi,
    quoteToken: productionTokens.eth,
    highlighted: false,
    orderPriority: 3.75,
    farmContract: productionFarmContracts.original,
    inactiveFlag: true,
    force0xMultiplier: true,
  },
  {
    pid: 22,
    lpAddress: '0xBF3220E8AF355F001d483439483A57A0A40Ced76',
    token: productionTokens.fxs,
    quoteToken: productionTokens.usdc,
    highlighted: false,
    orderPriority: 3.8,
    farmContract: productionFarmContracts.original,
    inactiveFlag: true,
    force0xMultiplier: true,
  },
  {
    pid: 23,
    lpAddress: '0xE35E509b0Fc2Af4c778B3Ec07e44B411944d7e20',
    token: productionTokens.frax,
    quoteToken: productionTokens.usdc,
    highlighted: false,
    orderPriority: 3.85,
    farmContract: productionFarmContracts.original,
    inactiveFlag: true,
    force0xMultiplier: true,
  },
  {
    pid: 24,
    lpAddress: '0xc23Ca4A1450574684FEDA1eC38d39Ffeb4490Ac7',
    token: productionTokens.sushi,
    quoteToken: productionTokens.usdc,
    highlighted: false,
    orderPriority: 3.1,
    farmContract: productionFarmContracts.original,
    inactiveFlag: true,
    force0xMultiplier: true,
  },
  {
    pid: 25,
    lpAddress: '0x9080C9325495b05cf74046f85f105324b3757610',
    token: productionTokens.knc,
    quoteToken: productionTokens.usdc,
    highlighted: false,
    orderPriority: 3.76,
    farmContract: productionFarmContracts.original,
    inactiveFlag: true,
    force0xMultiplier: true,
  },
  {
    pid: 26,
    lpAddress: '0xE2d6C9ED12Bb7Da16b285599Ca09F9320a0Bac32',
    token: productionTokens.uni,
    quoteToken: productionTokens.usdc,
    highlighted: false,
    orderPriority: 3.2,
    farmContract: productionFarmContracts.original,
    inactiveFlag: true,
    force0xMultiplier: true,
  },
  {
    pid: 27,
    lpAddress: '0x97E4458AF1D18ce8f8cD318e5BF728037B952dE4',
    token: productionTokens.qi,
    quoteToken: productionTokens.usdc,
    highlighted: false,
    orderPriority: 5.95,
    farmContract: productionFarmContracts.original,
    inactiveFlag: true,
    force0xMultiplier: true,
  },
  {
    pid: 28,
    lpAddress: '0x948ff92a7Ae9898edF1837748A8A2dc3C49Cf8c4',
    token: productionTokens.dg,
    quoteToken: productionTokens.usdc,
    highlighted: false,
    orderPriority: 3.79,
    farmContract: productionFarmContracts.original,
    inactiveFlag: true,
    force0xMultiplier: true,
  },
  // ADD_NEW_PROD_PLACEHOLDER
  // Copy, uncomment and fill to add new farm
  // {
  //   pid: ,
  //   lpAddress: '0x',
  //   token: productionTokens.,
  //   quoteToken: productionTokens.,
  //   highlighted: false,
  //   orderPriority: ,
  //   farmContract: 'multiReward1' as const,
  //   rewardToken1: productionTokens.,
  //   rewardToken2: productionTokens.,
  // },
]
  .sort((a, b) => (a.orderPriority || 0) - (b.orderPriority || 0))
  .map(addLpSymbolToConfig);

export const stagingFarms: FarmConfig[] = [
  // Multi reward farms
  {
    pid: 0,
    lpAddress: '0xdd342775815842CA9DC14B8B8845228b932c03DF',
    token: stagingTokens.dil,
    quoteToken: stagingTokens.usd,
    highlighted: true,
    farmContract: stagingFarmContracts.multiReward1,
  },
  {
    pid: 0,
    excluded: true,
    lpAddress: '0x4bbc5A087194B239127670AF1871757Ec928AA23',
    token: stagingTokens.pip,
    quoteToken: stagingTokens.eth,
    highlighted: false,
    farmContract: stagingFarmContracts.multiReward2,
    force0xMultiplier: true,
  },
  {
    pid: 0,
    lpAddress: '0x8d79726f760766aD231427B467F5a15C74e536B7',
    token: stagingTokens.idex,
    quoteToken: stagingTokens.eth,
    highlighted: true,
    farmContract: stagingFarmContracts.multiReward3,
  },
  // Single reward farms
  {
    pid: 0,
    lpAddress: '0xc88F9112fA718A058E98293a5A2Fa487C92874C1',
    token: stagingTokens.idex,
    quoteToken: stagingTokens.usd,
    highlighted: true,
    farmContract: stagingFarmContracts.original,
  },
  {
    pid: 1,
    lpAddress: '0xC1390DE997E703072b49326d6aa9DDADAdd4D166',
    token: stagingTokens.pip,
    quoteToken: stagingTokens.usd,
    highlighted: false,
    farmContract: stagingFarmContracts.original,
    inactiveFlag: true,
  },
].map(addLpSymbolToConfig);

export const devFarms: FarmConfig[] = [
  {
    pid: 0,
    lpAddress: '0x43F9f2BFc16329C7D2a217126E83Ce2D8854ee49',
    token: devTokens.idex,
    quoteToken: devTokens.usd,
    highlighted: true,
    farmContract: devFarmContracts.original,
  },
  {
    pid: 1,
    lpAddress: '0x3e5Cb82aa4Bdabf2386822D203e0234c87bC42c3',
    token: devTokens.pip,
    quoteToken: devTokens.usd,
    highlighted: false,
    farmContract: devFarmContracts.original,
  },
  {
    pid: 2,
    lpAddress: '0x4bCfB52F7a1fa2Ce4082e1fa317Bed920144b96F',
    token: devTokens.dil,
    quoteToken: devTokens.usd,
    highlighted: false,
    farmContract: devFarmContracts.original,
  },
  {
    // excluded: true,
    pid: 3,
    lpAddress: '0x22ADf3c0Ab05b2Fa56c5144f5261C38B9B3F0D16',
    token: devTokens.matic,
    quoteToken: devTokens.usd,
    highlighted: false,
    farmContract: devFarmContracts.original,
  },
].map(addLpSymbolToConfig);
