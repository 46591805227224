import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';
// import { Helmet } from 'react-helmet-async';
// import { useLocation } from 'react-router';
// import { customMeta, META_TAGS } from 'config/constants/meta';
import Container from './Container';

const StyledPage = styled(Container)`
  /* Minus desktop Header and Footer (and Footer top margin), 64px - 54px - 38px */
  min-height: calc(100vh - 210px);
  padding-top: 16px;
  padding-bottom: 16px;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding-top: 0;
    padding-bottom: 24px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding-top: 0;
    padding-bottom: 32px;
  }
`;

const PageMeta = () => {
  // const { pathname } = useLocation();
  // const idexPriceUsd = usePriceIdexUsd();
  // const idexPriceUsdDisplay = idexPriceUsd.eq(0)
  //   ? ''
  //   : `$${idexPriceUsd.toNumber().toLocaleString(undefined, {
  //       minimumFractionDigits: 3,
  //       maximumFractionDigits: 3,
  //     })}`;
  // const pageMeta = customMeta[pathname] || {};
  // const { title } = {
  //   ...META_TAGS,
  //   ...pageMeta,
  // };
  // const pageTitle = idexPriceUsdDisplay
  //   ? [title, idexPriceUsdDisplay].join(' - ')
  //   : title;
  return null;
  // return (
  //   <Helmet>
  //     {/* <title>{pageTitle}</title> */}
  //     {/* <meta property="og:description" content={description} />
  //     <meta property="og:title" content={title} />
  //     <meta property="og:description" content={description} />
  //     <meta property="og:image" content={image} />

  //     <meta charSet="utf-8" />
  //     <meta httpEquiv="Content-Language" content="en_US" />
  //     <meta name="google" content="notranslate" />
  //     <meta name="theme-color" content="#000000" />
  //     <meta name="author" content="IDEX" />
  //     <meta name="keywords" content={keywords.join(',')} />

  //     <meta property="name" content="IDEX Farms" />
  //     <meta property="og:type" content="website" />
  //     <meta property="og:url" content={domain} />

  //     <meta name="twitter:card" content="summary_large_image" />
  //     <meta name="twitter:site" content="@idexio" />
  //     <meta name="twitter:creator" content="@idexio" />
  //     <meta name="twitter:title" content={title} />
  //     <meta
  //       name="twitter:description"
  //       property="og:description"
  //       itemProp="description"
  //       content={description}
  //     />
  //     <meta name="twitter:image" content={image} /> */}
  //     {/* <meta name="twitter:domain" content="farms.idex.io" /> */}
  //   </Helmet>
  // );
};

const Page: React.FC<{ children: ReactNode; footer?: ReactNode }> = ({
  children,
  footer,
}) => {
  return (
    <>
      <PageMeta />
      <StyledPage>{children}</StyledPage>
      {footer}
    </>
  );
};

export default Page;
