import BigNumber from 'bignumber.js';
import React, { useCallback, useMemo, useState } from 'react';
import { Box, Button, Modal } from '@idexio/dev-idex-swap-uikit';
import ModalActions from 'components/ModalActions';
import ModalInput from 'components/ModalInput';
import useI18n from 'hooks/useI18n';
import { getFullDisplayBalance } from 'utils/formatBalance';
import { useToast } from 'state/hooks';
import SpinningRoundedBorder from 'components/SpinningRoundedBorder/SpinningRoundedBorder';

interface WithdrawModalProps {
  max: BigNumber;
  onConfirm: (amount: string) => void;
  onDismiss?: () => void;
  tokenName?: string;
}

const WithdrawModal: React.FC<WithdrawModalProps> = ({
  onConfirm,
  onDismiss,
  max,
  tokenName = '',
}) => {
  const [value, setValue] = useState('');
  const [pendingTx, setPendingTx] = useState(false);
  const { toastError, toastSuccess } = useToast();
  const TranslateString = useI18n();
  const fullBalance = useMemo(() => {
    return getFullDisplayBalance(max);
  }, [max]);
  const fullBalanceNumber = useMemo(() => Number(fullBalance), [fullBalance]);
  const isOverAvailableBalance = useMemo(
    () => Number(value) > fullBalanceNumber,
    [fullBalanceNumber, value]
  );

  const handleChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      setValue(event.currentTarget.value);
    },
    [setValue]
  );

  const handleSelectMax = useCallback(() => {
    setValue(fullBalance);
  }, [fullBalance, setValue]);

  return (
    <Modal
      title={TranslateString(1126, 'Unstake LP tokens')}
      onDismiss={onDismiss}
    >
      <ModalInput
        onSelectMax={handleSelectMax}
        onChange={handleChange}
        value={value}
        max={fullBalanceNumber}
        symbol={tokenName}
        inputTitle={TranslateString(588, 'Unstake')}
      />
      <ModalActions>
        <Button variant="secondary" onClick={onDismiss} width="100%">
          {TranslateString(462, 'Cancel')}
        </Button>
        <Button
          disabled={
            pendingTx || value === '' || value === '0' || isOverAvailableBalance
          }
          endIcon={
            pendingTx ? (
              <Box ml="1">
                <SpinningRoundedBorder inline size={24} />
              </Box>
            ) : undefined
          }
          onClick={async () => {
            setPendingTx(true);
            try {
              await onConfirm(value);
              toastSuccess('LP tokens successfully removed');
            } catch (error) {
              console.warn(error);
              toastError(error?.message);
            }
            setPendingTx(false);
            if (onDismiss) {
              onDismiss();
            }
          }}
          width="100%"
        >
          {pendingTx
            ? TranslateString(488, 'Pending Confirmation')
            : TranslateString(464, 'Confirm')}
        </Button>
      </ModalActions>
    </Modal>
  );
};

export default WithdrawModal;
