import React from 'react';
import {
  Flex,
  Box,
  VerifiedIcon,
  Text,
  Link,
  LinkExternal,
  useMatchBreakpoints,
} from '@idexio/dev-idex-swap-uikit';

import { getCoinIcon } from 'config/coinIcons';
import { useIsRestricted } from 'components/RestrictedBanner';

export interface ExpandableSectionProps {
  lpLabel?: string;
  tokenASymbol: string;
  tokenBSymbol: string;

  chainScanAddress?: string;
  removed?: boolean;
  addLiquidityUrl?: string;
}

const CardHeading: React.FC<ExpandableSectionProps> = ({
  lpLabel,
  tokenASymbol,
  tokenBSymbol,
  chainScanAddress,
  removed,
  addLiquidityUrl,
}) => {
  const isRestricted = useIsRestricted();
  const { breakpointEqualOrSmallerThan } = useMatchBreakpoints();
  const isMobile = breakpointEqualOrSmallerThan('lg');
  return (
    <Flex
      alignItems="center"
      height="100%"
      width="100%"
      py="3"
      pt={isMobile ? '24px' : '3'}
    >
      <div style={{ width: '100%' }}>
        <Flex
          alignItems="center"
          justifyContent={isMobile ? 'center' : 'flex-start'}
        >
          <Box paddingRight="8px">
            <img
              src={getCoinIcon(tokenASymbol)}
              alt={tokenASymbol}
              style={{ maxWidth: '50%', height: 30, width: 30 }}
            />
            <img
              src={getCoinIcon(tokenBSymbol)}
              alt={tokenBSymbol}
              style={{ maxWidth: '50%', height: 30, width: 30 }}
            />
          </Box>
          <Text as="h2" bold fontSize="16px">
            {lpLabel?.split(' ')[0]}
          </Text>
          <Box pl="8px">
            <VerifiedIcon color="primaryAlt" />
          </Box>
        </Flex>
        <Flex
          justifyContent={isMobile ? 'center' : 'flex-start'}
          flexWrap="wrap"
        >
          {!removed && !isRestricted && (
            <Link
              pt="2"
              pr="3"
              pb="1"
              pl="0"
              href={addLiquidityUrl}
              style={{ whiteSpace: 'nowrap' }}
              small
            >
              {/* {lpLabel} */}
              Get LP tokens
            </Link>
          )}
          <LinkExternal
            pt="2"
            pb="1"
            href={chainScanAddress}
            style={{ whiteSpace: 'nowrap' }}
            small
          >
            View Contract
          </LinkExternal>
        </Flex>
      </div>
    </Flex>
  );
};

export default CardHeading;
