import BigNumber from 'bignumber.js';
import { LP_DECIMALS } from 'config/constants/farms';

export const getBalanceNumber = (
  balance: BigNumber,
  decimals = LP_DECIMALS
) => {
  const displayBalance = new BigNumber(balance).dividedBy(
    new BigNumber(10).pow(decimals)
  );
  return displayBalance.toNumber();
};

export const getFullDisplayBalance = (
  balance: BigNumber,
  decimals = LP_DECIMALS
) => {
  return balance.dividedBy(new BigNumber(10).pow(decimals)).toFixed();
};
