import { useMemo } from 'react';
import Web3 from 'web3';
import { useWeb3React } from '@web3-react/core';
import { getWeb3NoAccount } from 'utils/web3';

let web3CachedLibrary: any = null;
let web3Instance: Web3 | null = null;

/**
 * Provides a web3 instance using the provider provided by useWallet
 * with a fallback of an httpProver
 * Recreate web3 instance only if the provider changed
 */
const useWeb3 = () => {
  const { library } = useWeb3React();

  const web3 = useMemo(() => {
    if (web3CachedLibrary === library && web3Instance) {
      // Make sure we use single web3 instance across all react components
      return web3Instance;
    }
    web3CachedLibrary = library;
    web3Instance = library ? new Web3(library) : getWeb3NoAccount();
    return web3Instance;
  }, [library]);

  return web3;
};

export default useWeb3;
