import React, { ReactNode, useMemo } from 'react';
import styled, { keyframes } from 'styled-components/macro';

const SmallImageSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 575.93 575.93">
    <g>
      <path
        style={{ fill: '#242345' }}
        d="M-90.37-116.3c-158.8,0-288-129.17-288-288s129.21-288,288-288,287.92,129.16,287.92,288S68.43-116.3-90.37-116.3Zm0-481.56C-197.09-597.86-284-511-284-404.27s86.91,193.59,193.63,193.59,193.55-86.86,193.55-193.59S16.36-597.86-90.37-597.86Z"
        transform="translate(378.38 692.23)"
      />
    </g>
    <g>
      <path
        style={{ fill: '#662bc9' }}
        d="M-90.37-116.3a287.06,287.06,0,0,1-198.52-79.35,47.19,47.19,0,0,1-1.66-66.73A47.32,47.32,0,0,1-223.82-264,192.75,192.75,0,0,0-90.37-210.68c106.73,0,193.55-86.86,193.55-193.59S16.36-597.86-90.37-597.86A47.15,47.15,0,0,1-137.55-645a47.15,47.15,0,0,1,47.18-47.18c158.8,0,287.92,129.16,287.92,288S68.43-116.3-90.37-116.3Z"
        transform="translate(378.38 692.23)"
      />
    </g>
  </svg>
);

const ImageSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 103.68 103.68">
      <g>
        <g>
          <path
            style={{
              fill: 'none',
              strokeMiterlimit: 10,
              strokeWidth: 3,
              stroke: '#242345',
            }}
            d="M1.5,51.84a50.34,50.34,0,1,1,50.34,50.34A50.34,50.34,0,0,1,1.5,51.84"
          />
          <path
            style={{
              fill: 'none',
              strokeMiterlimit: 10,
              strokeWidth: 3,
              stroke: '#544294', // size < 50 ? 'var(--mainLight)' : '#544294',
              strokeLinecap: 'round',
            }}
            d="M51.84,1.5A50.34,50.34,0,1,1,32.49,98.33"
          />
        </g>
      </g>
    </svg>
  );
};

const Wrapper = styled.div<{ sizePixels: number; displayInline: boolean }>`
  ${props =>
    props.displayInline ? 'display: inline-block;' : 'margin: 0 auto;'}
  position: relative;
  width: ${props => props.sizePixels}px;
  height: ${props => props.sizePixels}px;
`;

type spinProps = {
  sizePixels: number;
  spinColor: 'yellow' | 'blue' | 'purple';
};

const rotateKeyframes = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

// ${props =>
//   props.spinColor
//     ? `background-image: linear-gradient(to bottom, transparent 10%, ${
//         props.spinColor === 'purple'
//           ? 'var(--purple2)'
//           : props.spinColor === 'yellow'
//           ? '#f4d259'
//           : '#3474a3'
//       } 100%);`
//     : ''}

const SpinWrapper = styled.div<spinProps>`
  width: ${props => props.sizePixels}px;
  height: ${props => props.sizePixels}px;
  padding: 2px;
  position: relative;

  animation-name: ${rotateKeyframes};
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  border-radius: 50%;

  /* > div {
    background-color: $ {props => props.backgroundColor || 'var(--black0)'};
    min-height: 100%;
    border-radius: 50%;
  } */
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;

  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type Props = {
  size?: number;
  inline?: boolean;
  children?: ReactNode;
  color?: 'blue' | 'yellow' | 'purple';
};

function SpinningRoundedBorder({ size = 100, color, inline, children }: Props) {
  const sizeEven = useMemo(() => {
    if (size % 2 === 0) {
      return size;
    }
    console.warn('Use only even numbers to prevent spinner wobbling.');
    return size - 1;
  }, [size]);
  return (
    <Wrapper sizePixels={sizeEven} displayInline={!!inline}>
      <SpinWrapper sizePixels={sizeEven} spinColor={color || 'purple'}>
        {sizeEven < 50 ? <SmallImageSvg /> : <ImageSvg />}
      </SpinWrapper>
      <Content>{children}</Content>
    </Wrapper>
  );
}

export default SpinningRoundedBorder;
