import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { ConnectorNames } from '@idexio/dev-idex-swap-uikit';
import Web3 from 'web3';
import config from 'config/constants';

const injected = new InjectedConnector({
  supportedChainIds: [config.chainId],
});

const walletconnect = new WalletConnectConnector({
  rpc: { [config.chainId]: config.chainRpcSoftwareWallet },
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  chainId: config.chainId,
});

// Docs https://docs.cloud.coinbase.com/wallet-sdk/docs/web3-react
const coinbaseWallet = new WalletLinkConnector({
  url: config.chainRpcSoftwareWallet,
  appName: 'IDEX',
  supportedChainIds: [config.chainId],
});

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
  [ConnectorNames.CoinbaseWallet]: coinbaseWallet,
  // [ConnectorNames.BSC]: bscConnector,
};

export const getLibrary = (provider): Web3 => {
  return provider;
};
