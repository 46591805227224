import {
  Button,
  useMatchBreakpoints,
  useWalletModal,
} from '@idexio/dev-idex-swap-uikit';
import { useIsRestricted } from 'components/RestrictedBanner';
import React from 'react';

interface Props {
  account: string | undefined;
  login: (connectorName: any) => void;
  logout: () => void;
}

const UserBlock: React.FC<Props> = ({ account, login, logout }) => {
  const isRestricted = useIsRestricted();
  const { breakpointEqualOrSmallerThan } = useMatchBreakpoints();
  const { onPresentConnectModal, onPresentAccountModal } = useWalletModal(
    login,
    logout,
    account
  );
  const accountEllipsis = account
    ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}`
    : null;
  const isMobile = breakpointEqualOrSmallerThan('sm');
  return (
    <div>
      {account ? (
        <Button
          variant="secondaryAlt"
          disabled={isRestricted}
          onClick={onPresentAccountModal}
        >
          {accountEllipsis}
        </Button>
      ) : (
        <Button
          variant="primary"
          disabled={isRestricted}
          onClick={onPresentConnectModal}
        >
          {isMobile ? 'Unlock' : 'Unlock Wallet'}
        </Button>
      )}
    </div>
  );
};

export default React.memo(
  UserBlock,
  (prevProps, nextProps) => prevProps.account === nextProps.account
);
