import Web3 from 'web3';
import { AbiItem } from 'web3-utils';
import web3NoAccount from 'utils/web3';

// Addresses
import { getIdexTokenAddress } from 'utils/addressHelpers';

// ABI
import erc20Abi from 'config/abi/erc20.json';
import lpTokenAbi from 'config/abi/lpToken.json';
import {
  masterChefIdexABI,
  masterchefMultiRewardsABI,
} from 'config/abi/masterchefAbi';

const getContract = (abi: any, address: string, web3?: Web3) => {
  const _web3 = web3 ?? web3NoAccount;
  return new _web3.eth.Contract(abi as unknown as AbiItem, address);
};

export const getErc20Contract = (address: string, web3?: Web3) => {
  return getContract(erc20Abi, address, web3);
};
export const getLpContract = (address: string, web3?: Web3) => {
  return getContract(lpTokenAbi, address, web3);
};
export const getIdexTokenContract = (web3?: Web3) => {
  return getErc20Contract(getIdexTokenAddress(), web3);
  // return getContract(erc20Abi, getIdexTokenAddress(), web3);
};
export const getMasterchefContract = (web3: Web3, address: string) => {
  return getContract(masterChefIdexABI, address, web3);
};

export const getMultiRewardsMasterchefContract = (
  web3: Web3,
  contractAddress: string
) => {
  return getContract(masterchefMultiRewardsABI, contractAddress, web3);
};
