import { useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';

import { useAppDispatch } from 'state';
import { fetchFarmUserDataAsync } from 'state/farms';
import { unstake } from 'utils/callHelpers';
import { FarmConfig } from 'config/constants/types';
import { useMasterchef } from './useContract';

const useUnstake = (farmConfig: FarmConfig) => {
  const dispatch = useAppDispatch();
  const { account } = useWeb3React();
  const masterChefContract = useMasterchef(farmConfig.farmContract);

  const { pid } = farmConfig;

  const handleUnstake = useCallback(
    async (amount: string) => {
      const txHash = await unstake(masterChefContract, pid, amount, account);
      dispatch(fetchFarmUserDataAsync(account));
      console.info(txHash);
    },
    [account, dispatch, masterChefContract, pid]
  );

  return { onUnstake: handleUnstake };
};

export default useUnstake;
