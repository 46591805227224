import React from 'react';
import { HelpIcon, Tooltip } from '@idexio/dev-idex-swap-uikit';

export default function MultiplierIcon({
  multiReward,
}: {
  multiReward: boolean;
}) {
  return (
    <Tooltip
      display="inline-block"
      height="1px"
      position="upToRight"
      content={
        multiReward ? (
          'For farms with multiple rewards, multiplier values are not calculated.'
        ) : (
          <div>
            The multiplier represents the amount of IDEX rewards each farm earns
            relative to all other farms.
            {/* <br />
          <br />
          For example, if a 1x farm was getting 1 IDEX per block, a 40x farm
          would be getting 40 IDEX per block. */}
          </div>
        )
      }
    >
      <HelpIcon color="textSubtle" width="17px" mb="-4px" mt="-4px" />
    </Tooltip>
  );
}
