import { useMemo } from 'react';
import { Contract } from 'web3-eth-contract';

import useWeb3 from 'hooks/useWeb3';
import {
  getErc20Contract,
  getMasterchefContract,
  getMultiRewardsMasterchefContract,
} from 'utils/contractHelpers';
import { FarmContract } from 'config/constants/types';

// Helper hooks to get specific contracts (by ABI)

export const useERC20 = (address: string) => {
  const web3 = useWeb3();
  return useMemo(() => getErc20Contract(address, web3), [address, web3]);
};

export function getMasterchefInstance(
  web3,
  farmContract: FarmContract
): Contract {
  if (farmContract.contractAbi === 'singleReward') {
    return getMasterchefContract(web3, farmContract.contractAddress);
  }
  return getMultiRewardsMasterchefContract(web3, farmContract.contractAddress);
}

/**
 * Return masterchef instance - original or multicontract based on param
 */
export const useMasterchef = (farmContract: FarmContract): Contract => {
  const web3 = useWeb3();
  return useMemo(
    () => getMasterchefInstance(web3, farmContract),
    [web3, farmContract]
  );
};

export const useMultiRewardsMasterchef = (
  contractAddress: string
): Contract => {
  const web3 = useWeb3();
  return useMemo(
    () => getMultiRewardsMasterchefContract(web3, contractAddress),
    [contractAddress, web3]
  );
};
