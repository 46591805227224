// const BSCSCAN_PREFIXES = {
//   56: '',
//   97: 'testnet.',
// };
// export function getBscScanAddress(chainId: number = NETWORK_CHAIN_ID) {
//   return `https://${BSCSCAN_PREFIXES[chainId]}bscscan.com`;
// }

import config from 'config/constants';

export function getExplorerAddress() {
  return config.chainBlockExplorer;
}
