import React, { useEffect, useCallback, useMemo, useReducer } from 'react';
import { useAppDispatch, useSelector } from 'state';
import BigNumber from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';
import styled from 'styled-components/macro';
import {
  breakpointMap,
  Text,
  useMatchBreakpoints,
  ArrowDownIcon,
  Tooltip,
  Flex,
  Box,
} from '@idexio/dev-idex-swap-uikit';

import { useApiPrices, useFarms } from 'state/hooks';
import useRefresh from 'hooks/useRefresh';
import { fetchFarmUserDataAsync } from 'state/farms';
import { Farm } from 'state/types';
import { getFarmApy } from 'utils/apy';
import { orderBy } from 'lodash';
// import FarmCard from './components/FarmIdexCard/FarmCard';
import { DEBUG_ENABLED } from 'config/constants/environments';
import { BLOCKS_PER_YEAR } from 'config';
import {
  controlsReducer,
  controlsInitialState,
} from './components/FarmControls/farmControlsReducer';
import FarmRow from './components/FarmRow/FarmRow';
import FarmControls from './components/FarmControls/FarmControls';
import { FarmWithStakedValue } from './components/FarmRow/types.d';
import flameIcon from './flame.svg';

const Heading = styled.h1`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: #ffffff;
  margin-top: 28px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  padding-right: 4px;
`;

const FARM_CONTROLS_ID = 'FarmControls';

const TableWrapper = styled.div`
  @media (min-width: 700px) and (max-width: ${breakpointMap.xl - 1}px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 18px;
  }
`;

const Farms: React.FC = () => {
  const rewardPerBlock = useSelector(
    state => state.contractsConfig.data?.rewardPerBlock
  );

  // const { pathname } = useLocation();
  // const isActive = !pathname.includes('history');
  const [controlsState, controlsDispatch] = useReducer(
    controlsReducer,
    controlsInitialState
  );

  const farmsAllWithLP = useFarms();

  const farmsLP = farmsAllWithLP.filter(
    farm => farm.farmContract.contractAbi === 'singleReward'
  );
  const farmsMultiRewardLP = farmsAllWithLP.filter(
    farm => farm.farmContract.contractAbi === 'multiReward'
  );

  const liquidityProviderFees24hUsdMap = useSelector(
    state => state.tokenStats.data?.liquidityProviderFees24hUsdMap
  );

  const { query, sortOption, stakedOnly } = controlsState;

  const { account } = useWeb3React();
  const apiPrices = useApiPrices();

  const dispatch = useAppDispatch();
  const { fastRefresh } = useRefresh();

  useEffect(() => {
    if (document.hidden) {
      return;
    }
    if (account) {
      dispatch(fetchFarmUserDataAsync(account));
    }
  }, [account, dispatch, fastRefresh]);

  const sortFarms = useCallback(
    (farms: FarmWithStakedValue[]): FarmWithStakedValue[] => {
      switch (sortOption) {
        case 'Name':
          return orderBy(
            farms,
            (farm: FarmWithStakedValue) => farm.lpSymbol,
            'asc'
          );
        case 'APR':
          return orderBy(
            farms,
            (farm: FarmWithStakedValue) => farm.apy || 0,
            'desc'
          );
        case 'Multiplier':
          return orderBy(
            farms,
            (farm: FarmWithStakedValue) =>
              farm.multiplier ? Number(farm.multiplier.slice(0, -1)) : 0,
            'desc'
          );
        case 'Liquidity':
          return orderBy(
            farms,
            (farm: FarmWithStakedValue) => Number(farm.totalLiqudityUsdBN),
            'desc'
          );
        case 'Staked':
          return orderBy(
            farms,
            (farm: FarmWithStakedValue) =>
              farm.userData ? Number(farm.userData.stakedBalance) : 0,
            'desc'
          );
        case 'Rewards':
          return orderBy(
            farms,
            (farm: FarmWithStakedValue) => {
              const { rewardToken1, rewardToken2 } = farm.farmContract;
              const rewardToken1PriceUsd =
                apiPrices?.[rewardToken1.address.toLowerCase()];
              const rewardToken2PriceUsd = rewardToken2
                ? apiPrices?.[rewardToken2.address.toLowerCase()]
                : undefined;
              const { earnings1, earnings2 } = farm.userData || {};
              const earningsToken1Usd =
                earnings1 && rewardToken1PriceUsd
                  ? earnings1 * rewardToken1PriceUsd
                  : 0;
              const earningsToken2Usd =
                earnings2 && rewardToken2PriceUsd
                  ? earnings2 * rewardToken2PriceUsd
                  : 0;
              return earningsToken1Usd + earningsToken2Usd;
            },
            'desc'
          );

        case 'Default':
        default:
          return farms;
      }
    },
    [sortOption, apiPrices]
  );

  const farmsList = useCallback(
    (farmsToDisplay: Farm[], calculateApy: boolean): FarmWithStakedValue[] => {
      let farmsToDisplayWithAPY: FarmWithStakedValue[] = farmsToDisplay.map(
        farm => {
          if (
            !farm.lpTotalInQuoteToken ||
            !apiPrices ||
            !farm.poolWeight ||
            !rewardPerBlock
          ) {
            return { ...farm, apy: undefined, totalLiqudityUsdBN: undefined };
          }
          const quoteTokenPriceUsd =
            apiPrices[farm.quoteToken.address.toLowerCase()];
          const totalLiquidityUsd = quoteTokenPriceUsd
            ? new BigNumber(farm.lpTotalInQuoteToken).times(quoteTokenPriceUsd)
            : undefined;
          const liquidityProviderFees24hUsd = liquidityProviderFees24hUsdMap
            ? liquidityProviderFees24hUsdMap[
                `${farm.token.symbol}-${farm.quoteToken.symbol}`
              ]
            : undefined;

          const { rewardToken1, rewardToken2 } = farm.farmContract;
          const rewardToken1PriceUsd =
            apiPrices?.[rewardToken1.address.toLowerCase()];

          const rewardToken2PriceUsd = rewardToken2
            ? apiPrices?.[rewardToken2.address.toLowerCase()]
            : undefined;

          const rewardsPerBlock =
            rewardPerBlock[farm.farmContract.contractAddress];

          if (DEBUG_ENABLED) {
            // eslint-disable-next-line no-console
            console.log(`FARM: ${farm.lpSymbol}`, {
              inactiveFlag: farm.inactiveFlag,
              totalLiquidityUsd: totalLiquidityUsd?.toString(),
              lpTotalInQuoteToken: farm.lpTotalInQuoteToken,
              rewardToken1PriceUsd,
              rewardToken2PriceUsd,
              rewardsPerBlock,
              liquidityProviderFees24hUsd,
              BLOCKS_PER_YEAR: BLOCKS_PER_YEAR.toString(),
              poolWeight: farm.poolWeight,
            });
          }

          const apy = isFarmInactive(farm)
            ? undefined
            : calculateApy &&
              totalLiquidityUsd &&
              rewardToken1PriceUsd &&
              rewardsPerBlock
            ? getFarmApy({
                poolWeight: new BigNumber(farm.poolWeight),
                rewardToken1PriceUsd,
                rewardToken2PriceUsd,
                reward1PerBlock: rewardsPerBlock[0],
                reward2PerBlock: rewardsPerBlock[1],
                poolLiquidityUsd: totalLiquidityUsd,
                liquidityProviderFees24hUsd,
              })
            : 0;
          return {
            ...farm,
            apy: apy ?? undefined,
            totalLiqudityUsdBN: totalLiquidityUsd,
          } as FarmWithStakedValue;
        }
      );

      if (query) {
        const lowercaseQuery = query.toLowerCase();
        farmsToDisplayWithAPY = farmsToDisplayWithAPY.filter(
          (farm: FarmWithStakedValue) =>
            farm.lpSymbol.toLowerCase().includes(lowercaseQuery)
        );
      }
      return farmsToDisplayWithAPY;
    },
    [query, apiPrices, liquidityProviderFees24hUsdMap, rewardPerBlock]
  );

  const isFarmInactive = (farm: Farm) =>
    // !(farm.lpSymbol === 'ILP-IDEX-USDC' && farm.farmContract.contractAbi === 'multiReward')
    farm.multiplier !== undefined
      ? farm.multiplier === '0X'
      : farm.inactiveFlag;

  const filterFarms = useCallback(
    (filterActive: boolean) => {
      const filteredByActive = [...farmsMultiRewardLP, ...farmsLP].filter(
        farm => {
          const farmInactive = isFarmInactive(farm);
          return (
            (filterActive ? !farmInactive : farmInactive) &&
            (!stakedOnly ||
              (farm.userData &&
                new BigNumber(farm.userData.stakedBalance).isGreaterThan(0)))
          );
        }
      );
      return sortFarms(farmsList(filteredByActive, filterActive));
    },
    [farmsLP, farmsList, farmsMultiRewardLP, sortFarms, stakedOnly]
  );

  const activeFarms = useMemo(() => filterFarms(true), [filterFarms]);
  const inactiveFarms = useMemo(() => filterFarms(false), [filterFarms]);

  const { breakpointEqualOrSmallerThan } = useMatchBreakpoints();
  const isMobile = breakpointEqualOrSmallerThan('lg');

  return (
    <>
      {activeFarms.length > 0 && (
        <>
          <Heading>
            <Flex>
              <Box pr="2">
                <img src={flameIcon} alt="Hot" />
              </Box>
              <Box pr="2">Dual Rewards Farms</Box>
              <Tooltip
                content="Dual Rewards Farms pay out rewards in more than one token, providing extra yield for liquidity providers."
                iconColor="#ffffff"
                icon="help"
              />
            </Flex>{' '}
            {isMobile && (
              <a href={`#${FARM_CONTROLS_ID}`}>
                <ArrowDownIcon />
              </a>
            )}
          </Heading>
          <TableWrapper>
            {activeFarms.map(farm => (
              <FarmRow
                key={`${farm.farmContract.contractAddress}_${farm.pid}`}
                farm={farm}
                account={account}
                removed={farm.multiplier === '0X'}
                isMobile={isMobile}
              />
            ))}
          </TableWrapper>
        </>
      )}
      <div id={FARM_CONTROLS_ID}>
        <FarmControls dispatch={controlsDispatch} state={controlsState} />
      </div>
      <TableWrapper>
        {inactiveFarms.length === 0 && (
          <Text p="3" pt="50px" pb="250px" textAlign="center">
            No farms found.
          </Text>
        )}
        {inactiveFarms.map(farm => (
          <FarmRow
            key={`${farm.farmContract.contractAddress}_${farm.pid}`}
            farm={farm}
            account={account}
            removed={farm.multiplier === '0X'}
            isMobile={isMobile}
          />
        ))}
      </TableWrapper>
    </>
  );
};

export default Farms;
