import config, { tokens } from 'config/constants';

/** IDEX token address */
export const getIdexTokenAddress = () => {
  return tokens.idex.address;
};

export const getMulticallAddress = () => {
  return config.multiCallContract;
};

/** Remove prefix of "ILP-IDEX-MATIC" and result to "IDEX-MATIC" */
export const readableLpName = (lpSymbol: string): string =>
  `${lpSymbol?.replace('ILP-', '')?.toUpperCase()} IDEX LP`;
