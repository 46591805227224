/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect, useCallback, useState } from 'react';
import { ethers } from 'ethers';
import BigNumber from 'bignumber.js';
import { useWeb3React } from '@web3-react/core';

import { useFarms } from 'state/hooks';
// import { getAddress } from 'utils/addressHelpers';
import { useMasterchef, useMultiRewardsMasterchef } from 'hooks/useContract';
import erc20 from 'config/abi/erc20.json';
import { getLpContract } from 'utils/contractHelpers';
import config, { farmsConfig } from 'config/constants';
import { masterChefIdexABI } from 'config/abi/masterchefAbi';
import useWeb3 from 'hooks/useWeb3';
import multicall from 'utils/multicall';
import { fetchFarm } from 'state/farms/fetchFarms';
import { fetchAssets } from './index';

function DebugFarmPid() {
  const { account } = useWeb3React();
  // const masterchefContract = useMasterchef();
  const masterchefContract = useMultiRewardsMasterchef(
    config.farmsContracts[1].contractAddress
  );

  useEffect(() => {
    if (!account) {
      console.warn('[DevFarms] No wallet');
      return;
    }
    masterchefContract.methods
      .poolLength()
      .call()
      .then(result => {
        console.log('[DevFarms] Farm pools lenght is ', result);
        for (let farmPid = 0; farmPid < result; farmPid++) {
          masterchefContract.methods
            .poolInfo(farmPid)
            .call()
            .then(farmResult => {
              console.log(
                `[DEBUGGER] Farm [PID=${farmPid}] info is:`,
                farmResult
              );
              console.log(`PID=${farmPid} LP_TOKEN=${farmResult.lpToken}`);
            });
        }
      })
      .catch(error => {
        console.warn('[DevFarms]', error);
      });
  }, [masterchefContract, account]);
  return null;
}

/**
 * Debug contracts
 * Include anywhere as standard react component
 */
export default function DevFarms() {
  useEffect(() => {
    fetchAssets().then(assets => console.log('ASSETS', assets));
    console.log('[DevFarms] Starting...');
  }, []);

  return <DebugFarmPid />;

  const farmsLP = useFarms();
  // const idexPrice = usePriceIdexUsd()
  const web3 = useWeb3();
  const { account } = useWeb3React();

  // const pancakeMasterchef = new web3.eth.Contract(masterChefPancakeABI as any, getAddress(masterChefPancake))
  // pancakeMasterchef.methods.poolInfo(PANCAKE_BUSD_BNB_LP.pid)
  // .call()
  // .then((result) => {
  //   console.log(`[DEBUGGER] POOL:`, PANCAKE_BUSD_BNB_LP, result)
  // })

  useEffect(() => {
    const contract = getLpContract(
      '0x2f7682b64b88149ba3250aee32db712964de5fa9'
    );
    console.log(contract);
    contract.methods
      .token1()
      .call()
      .then(result => console.log('[DEBUGGER] TOKEN', result))
      .catch(error => console.warn('[DEBUGGER] TOKEN ERROR: ', error));
  }, []);

  return null;

  // "[{"address":"0x5dfd5da73A6053fD0FCB9644dbd782AeB5471411",
  //   "name":"balanceOf", "params":["0xaBeA91ADA94cea27E59D3f9f2FFce517D27f4A3c"]}, -- token LP balance

  // {"address":"0x0856978F7fFff0a2471B4520E3521c4B3343e36f",
  // "name":"balanceOf", "params":["0xaBeA91ADA94cea27E59D3f9f2FFce517D27f4A3c"]},  -- quote LP balance

  // {"address":"0xaBeA91ADA94cea27E59D3f9f2FFce517D27f4A3c",
  // "name":"balanceOf", "params":["0xfDBeC24F0eCAD1178F852BC470ea84D06fAc2D56"]}, -- masterchef LP balance

  // {"address":"0xaBeA91ADA94cea27E59D3f9f2FFce517D27f4A3c", "name":"totalSupply"}, -- LP supply
  // {"address":"0x5dfd5da73A6053fD0FCB9644dbd782AeB5471411", "name":"decimals"}, -- token decimals
  // {"address":"0x0856978F7fFff0a2471B4520E3521c4B3343e36f", "name":"decimals"}]" -- quote decimals

  // useEffect(() => {
  //   const farm = farms[0]
  //   const address = getAddress(farm.quoteToken.address)
  //   console.log('[DEBUGGER] address', address)
  //   const contract = getLpContract(address)

  //   contract.methods
  //     .name()
  //     .call()
  //     .then((result) => console.log('[DEBUGGER] NAME', result))
  //     .catch((error) => console.warn('[DEBUGGER] NAME ERROR: ', error))

  //   contract.methods
  //     .balanceOf(getAddress(farm.lpAddresses))
  //     .call()
  //     .then((result) => console.log('[DEBUGGER] BALANCE', result))
  //     .catch((error) => console.warn('[DEBUGGER] ERROR: ', error))

  //   return null
  // }, [account])

  // return null

  // const cakePrice = usePriceCakeBusd()
  // const prices = useGetApiPrices()

  const pancakeMasterchef = new web3.eth.Contract(
    masterChefIdexABI as any,
    'x0asd'
  );
  // pancakeMasterchef.methods
  //   .poolInfo(PANCAKE_BUSD_BNB_FARM.pid)
  //   .call()
  //   .then(result => {
  //     console.log(`[DEBUGGER] POOL:`, PANCAKE_BUSD_BNB_FARM, result);
  //   });

  return null;

  const masterchefContract = useMasterchef(config.farmsContracts[0]);
  useEffect(() => {
    if (!account) {
      console.warn('[DEBUGGER] No wallet');
      return;
    }
    console.warn('[DEBUGGER] Running...');

    // Get max farm index
    // const result = masterchefContract.methods.poolLength()
    // console.log('resultresultresult', result)

    // Get farm pool info by index (from 0 to length)
    const POOL_PID = 0;
    masterchefContract.methods
      .poolInfo(POOL_PID)
      .call()
      .then(result => {
        console.log(`[DEBUGGER] pool PID ${POOL_PID}:`, result);
      });

    //   address: masterChefAddress,
    //   name: IDEX_CONTRACTS ? 'pendingReward' : 'pendingCake',
    //   params: [farm.pid, account], // PID in contract ?
    // Get rewards
    console.log('[DEBUGGER] account', account);
    masterchefContract.methods
      .pendingReward(POOL_PID, account)
      .call()
      .then(result => {
        console.log('[DEBUGGER] Pending Reward', result);
      })
      .catch(error => {
        console.error('[DEBUGGER] Pending Reward', error);
      });
  }, [masterchefContract, account]);

  //   masterChefContract.methods
  //   .leaveStaking('0')
  //   .sendAsync({ from: account, gas: 200000 })
  //   .on('transactionHash', (tx) => {
  //     return tx.transactionHash
  //   })
  // }

  const activeFarms = farmsLP.filter(farm => farm.multiplier !== '0X');
  const inactiveFarms = farmsLP.filter(farm => farm.multiplier === '0X');

  const stakedOnlyFarms = activeFarms.filter(
    farm =>
      farm.userData &&
      new BigNumber(farm.userData.stakedBalance).isGreaterThan(0)
  );

  const stakedInactiveFarms = inactiveFarms.filter(
    farm =>
      farm.userData &&
      new BigNumber(farm.userData.stakedBalance).isGreaterThan(0)
  );

  console.log('[DEBUGGER]', {
    activeFarms,
    inactiveFarms,
    stakedOnlyFarms,
    stakedInactiveFarms,
  });

  return null;
}
