import React from 'react';
import styled from 'styled-components/macro';
import { Modal, Text, Link, Flex } from '@idexio/dev-idex-swap-uikit';
import useI18n from 'hooks/useI18n';
import { calculateEarnedPerThousandDollars } from 'utils/compoundApyHelpers';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, auto);
  margin-bottom: 24px;
`;

const GridItem = styled.div`
  margin-bottom: '10px';
`;

const Description = styled(Text)`
  max-width: 320px;
  margin-bottom: 28px;
`;

interface ApyCalculatorModalProps {
  onDismiss?: () => void;
  lpLabel?: string;
  apy?: number;
  addLiquidityUrl?: string;
}

const ApyCalculatorModal: React.FC<ApyCalculatorModalProps> = ({
  onDismiss,
  lpLabel,
  apy,
  addLiquidityUrl,
}) => {
  const TranslateString = useI18n();

  if (!apy && apy !== 0) {
    return null;
  }

  const getCompound = (numberOfDays: number) =>
    calculateEarnedPerThousandDollars({
      numberOfDays,
      farmApy: apy,
      rewardTokenPriceUsd: 10, // Magic number (dont ask me why)
    });

  const aprDay = apy / 365;
  const aprWeek = getCompound(7);
  const aprMonth = getCompound(30);
  const aprYear = getCompound(365);

  // TODO: only calculate when visible
  return (
    <Modal title="ROI" onDismiss={onDismiss}>
      <Grid>
        <GridItem>
          <Text
            fontSize="12px"
            bold
            color="textSubtle"
            textTransform="uppercase"
            mb="20px"
          >
            {TranslateString(860, 'Timeframe')}
          </Text>
        </GridItem>
        <GridItem>
          <Text
            fontSize="12px"
            bold
            color="textSubtle"
            textTransform="uppercase"
            mb="20px"
          >
            {TranslateString(858, 'ROI')}
          </Text>
        </GridItem>
        {/* 1 day row */}
        <GridItem>
          <Text>1d</Text>
        </GridItem>
        <GridItem>
          <Text>{aprDay.toFixed(2)}%</Text>
        </GridItem>
        {/* 7 day row */}
        <GridItem>
          <Text>7d</Text>
        </GridItem>
        <GridItem>
          <Text>{aprWeek.toFixed(2)}%</Text>
        </GridItem>
        {/* 30 day row */}
        <GridItem>
          <Text>30d</Text>
        </GridItem>
        <GridItem>
          <Text>{aprMonth.toFixed(2)}%</Text>
        </GridItem>
        {/* 365 day / APY row */}
        <GridItem>
          <Text>365d(APY)</Text>
        </GridItem>
        <GridItem>
          <Text>{aprYear.toFixed(2)}%</Text>
        </GridItem>
      </Grid>
      <Description fontSize="12px" color="textSubtle">
        {TranslateString(
          866,
          'Calculated based on current rates. Compounding once daily. Rates are estimates provided for your convenience only, and by no means represent guaranteed returns.'
        )}
      </Description>
      <Flex justifyContent="center">
        <Link href={addLiquidityUrl}>
          {TranslateString(999, 'Get')} {lpLabel}
        </Link>
      </Flex>
    </Modal>
  );
};

export default ApyCalculatorModal;
