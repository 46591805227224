import React from 'react';
import { LinkExternal } from '@idexio/dev-idex-swap-uikit';
import styled from 'styled-components/macro';
import config from 'config/constants';

const Wrap = styled.div`
  padding: 14px;

  background: #000;
  border-top: 1px solid #333;

  text-align: center;
  font-size: 16px;
  line-height: 22px;
  color: #a1a1a1;
  margin: 38px 0 0 0;
`;

export default function FooterDisclaimer() {
  return (
    <Wrap>
      APR is an estimate and subject to change.{' '}
      <LinkExternal
        inline
        href={`${config.exchangeUrl}/legal`}
        ml="2"
        target="_self"
      >
        Learn more
      </LinkExternal>
      .
    </Wrap>
  );
}
