import { RestResponseLiquidityPool } from '@idexio/idex-sdk';
import { ethers } from 'ethers';
import BigNumber from 'bignumber.js';
import erc20 from 'config/abi/erc20.json';
import defaultMasterchefABI from 'config/abi/masterchefAbi';
import multicall from 'utils/multicall';
import { FarmConfig } from 'config/constants/types';
import { farmsConfig } from 'config/constants';
import { Farm } from 'state/types';
import { LP_DECIMALS } from 'config/constants/farms';
import { fetchLiquidityPools } from 'api';
import { getEthersProviderNoAccount } from 'utils/web3';

// TODO: MetaMask: 'ethereum.send(...)' is deprecated and may be removed in the future.
// Please use 'ethereum.sendAsync(...)' or 'ethereum.request(...)' instead.

export const fetchFarm = async (
  farmConfig: FarmConfig,
  apiPools: RestResponseLiquidityPool[],
  masterchefABI = defaultMasterchefABI
): Promise<Farm> => {
  const apiPool = apiPools.find(
    pool =>
      pool.liquidityToken.toLowerCase() === farmConfig.lpAddress.toLowerCase()
  );
  if (!apiPool) {
    throw new Error(`Liquidity pool not found ${farmConfig.lpSymbol}`);
  }

  const lpContract = new ethers.Contract(
    farmConfig.lpAddress,
    erc20,
    getEthersProviderNoAccount()
  );
  const lpTokensInFarm = await lpContract.functions.balanceOf(
    farmConfig.farmContract.contractAddress
  );
  const lpTotalSupply = apiPool?.totalLiquidity;

  if (!lpTotalSupply) {
    throw new Error(
      `Unknown api total liquidity for farm ${farmConfig.lpSymbol}`
    );
  }

  const isABaseToken =
    farmConfig.token.address.toLowerCase() === apiPool.tokenA.toLowerCase();
  const tokenBalanceLP = isABaseToken ? apiPool.reserveA : apiPool.reserveB;
  const quoteTokenBalanceLP = isABaseToken
    ? apiPool.reserveB
    : apiPool.reserveA;

  const lpTokensInFarmsBN = new BigNumber(lpTokensInFarm.toString());
  const lpTokenTotalSupplyBN = new BigNumber(lpTotalSupply);

  // Ratio in % a LP tokens that are in staking, vs the total number in circulation
  const lpTokenRatio = lpTokensInFarmsBN.div(lpTokenTotalSupplyBN);

  // Total value in staking in quote token value
  const lpTotalInQuoteToken = new BigNumber(quoteTokenBalanceLP)
    .div(new BigNumber(10).pow(farmConfig.quoteToken.decimals)) // LP_DECIMALS))
    .times(new BigNumber(2))
    .times(lpTokenRatio);

  // Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
  const tokenAmount = new BigNumber(tokenBalanceLP)
    .div(new BigNumber(10).pow(farmConfig.token.decimals))
    .times(lpTokenRatio);

  const quoteTokenAmount = new BigNumber(quoteTokenBalanceLP)
    .div(new BigNumber(10).pow(farmConfig.quoteToken.decimals))
    .times(lpTokenRatio);

  // TODO: Add to the API (?) or cache it at least for like 15 seconds
  // so user cannot refresh like crazy
  const [info, totalAllocPoint] = await multicall(masterchefABI, [
    {
      address: farmConfig.farmContract.contractAddress,
      name: 'poolInfo',
      params: [farmConfig.pid],
    },
    {
      address: farmConfig.farmContract.contractAddress,
      name: 'totalAllocPoint',
    },
  ]);

  const allocPoint = farmConfig.force0xMultiplier
    ? new BigNumber(0)
    : new BigNumber(info.allocPoint._hex);
  const poolWeight = allocPoint.div(new BigNumber(totalAllocPoint));

  return {
    ...farmConfig,
    tokenAmount: tokenAmount.toJSON(),
    quoteTokenAmount: quoteTokenAmount.toJSON(),

    lpTokenTotalSupply: lpTokenTotalSupplyBN
      .div(new BigNumber(10).pow(LP_DECIMALS))
      .toJSON(),
    lpTokensInFarm: lpTokensInFarmsBN
      .div(new BigNumber(10).pow(LP_DECIMALS))
      .toJSON(),

    lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
    tokenPriceVsQuote: quoteTokenAmount.div(tokenAmount).toJSON(),
    poolWeight: poolWeight?.toJSON(),
    multiplier: `${allocPoint.div(100).toString()}X`,
  };
};

const fetchFarms = async () => {
  const pools = await fetchLiquidityPools();
  const data = await Promise.all(
    farmsConfig
      // To test with first farm uncomment
      // .filter((f, i) => i === 0)
      .map(async farmConfig => {
        return fetchFarm(farmConfig, pools);
      })
  );
  return data;
};

export default fetchFarms;
