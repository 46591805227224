import React, { useEffect } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { ResetCSS } from '@idexio/dev-idex-swap-uikit';
import BigNumber from 'bignumber.js';

import { RestrictedBanner } from 'components/RestrictedBanner';
import useEagerConnect from 'hooks/useEagerConnect';
import FarmsWithStats from 'views/Farms/FarmsWithStats';
import { fetchAndDispatchRewardPerBlock } from 'state/contractsConfig';
import NotFound from 'views/NotFound';
import { staticDispatch } from 'state';
import GlobalStyle from './style/Global';
import Menu from './components/Menu';
import SuspenseWithChunkError from './components/SuspenseWithChunkError';
import ToastListener from './components/ToastListener';
import PageLoader from './components/PageLoader';
import DevDebugger from './api/DevDebugger';
import DevTokens from './api/DevTokens';
import DevFarms from './api/DevFarms';
import history from './routerHistory';

// Route-based code splitting
// const Farms = lazy(() => import('./views/FarmsWithStats'));

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
});

const App: React.FC = () => {
  useEagerConnect();

  useEffect(() => {
    staticDispatch(fetchAndDispatchRewardPerBlock());
  }, []);

  return (
    <Router history={history}>
      <ResetCSS />
      <GlobalStyle />
      <RestrictedBanner />
      <Menu>
        <SuspenseWithChunkError fallback={<PageLoader />}>
          <Switch>
            {/* <Route path="/" exact component={FarmsWithStats} /> */}
            <Redirect path="/" to="/farms" exact />
            {/* <Route path="/history" exact component={FarmsWithStats} /> */}
            <Route path="/farms" component={FarmsWithStats} />
            {/* Deprecated (enable for usage in iframe)
            <Route
              path="/liquidity/add/:symbolA/:symbolB"
              component={AddLiquidityRedirect}
            />
            <Route path="/liquidity/add" component={AddLiquidityRedirect} /> */}
            <Route path="/dev/tokens" component={DevTokens} />
            <Route path="/dev/farms" component={DevFarms} />
            <Route path="/dev/debugger" component={DevDebugger} />
            <Route component={NotFound} />
          </Switch>
        </SuspenseWithChunkError>
      </Menu>
      <ToastListener />
    </Router>
  );
};

export default React.memo(App);
