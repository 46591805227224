import React from 'react';
import { Heading } from '@idexio/dev-idex-swap-uikit';

import Page from 'components/layout/Page';
import Farms from './Farms';
import StatsBoxes from './components/StatsBoxes/StatsBoxes';
import {
  useFetchPriceList,
  useFetchPublicData,
  useFetchAndPollTokenStats,
} from '../../state/hooks';
import FooterDisclaimer from './components/FooterDisclaimer';
import FooterVersion from './components/FooterVersion';
import MarketingArea from './components/MarketingArea/MarketingArea';

const FarmsWithStats: React.FC = () => {
  // fetchExchangeInfo()
  // fetchAssets()

  useFetchPublicData();
  useFetchPriceList();
  useFetchAndPollTokenStats();

  return (
    <Page footer={<FooterDisclaimer />}>
      <Heading
        as="h1"
        size="lg"
        mb="3"
        ml={['8px', '8px', '8px', '25px']}
        pr="4"
      >
        Farm IDEX LP tokens and maximize yield
      </Heading>
      <Heading
        as="h2"
        size="md"
        color="textSubtle"
        mb="3"
        ml={['8px', '8px', '8px', '25px']}
        pr="4"
      >
        Earn rewards for providing liquidity to IDEX Hybrid Liquidity pools
      </Heading>
      <StatsBoxes />
      <MarketingArea />
      <Farms />
      <FooterVersion />
    </Page>
  );
};

export default FarmsWithStats;
