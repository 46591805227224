import { useState, useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';

import { getErc20Contract } from 'utils/contractHelpers';
import { useFarmUser, useToast } from 'state/hooks';
import useWeb3 from 'hooks/useWeb3';
import { useApproveFarm } from 'hooks/useApprove';
import { FarmWithStakedValue } from './types.d';

export const useFarmApproval = (farm: FarmWithStakedValue) => {
  const { account } = useWeb3React();
  const [requestedApproval, setRequestedApproval] = useState(false);
  const { allowance } = useFarmUser(farm);
  const { toastError } = useToast();
  const isApproved = account && allowance ? allowance.isGreaterThan(0) : null;
  const web3 = useWeb3();

  const lpContract = getErc20Contract(farm.lpAddress, web3);

  const { onApprove } = useApproveFarm(farm.farmContract, lpContract);

  const handleApprove = useCallback(async () => {
    setRequestedApproval(true);
    try {
      await onApprove();
      // TODO: idex any type
    } catch (error: any) {
      console.error(error);
      toastError(error?.message);
    }
    setRequestedApproval(false);
  }, [onApprove, toastError]);
  return {
    handleApprove,
    requestedApproval,
    isApproved,
  };
};
