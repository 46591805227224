import unknown from './unknown.svg';

// Coins
import AAVE from './images/AAVE.png';
import BIFI from './images/BIFI.png';
import BNB from './images/BNB.png';
import CRV from './images/CRV.png';
import CVX from './images/CVX.webp';
import DAI from './images/DAI.png';
import ETH from './images/ETH.png';
import FRAX from './images/FRAX.png';
import FXS from './images/FXS.png';
import GHST from './images/GHST.png';
import IDEX from './images/IDEX.svg';
import LINK from './images/LINK.png';
import MAI from './images/MAI.png';
import MANA from './images/MANA.png';
import MATIC from './images/MATIC.png';
import SX from './images/SX.png';
import SYN from './images/SYN.png';
import USDC from './images/USDC.png';
import USDT from './images/USDT.png';
import WBTC from './images/WBTC.png';
import QUICK from './images/QUICK.png';
import SUSHI from './images/SUSHI.png';
import KNC from './images/KNC.png';
import UNI from './images/UNI.png';
import QI from './images/QI.png';
import DG from './images/DG.png';
import ALGB from './images/ALGB.png';
// ADD_NEW_IMPORT_PLACEHOLDER

/**
 * Polygon assets
 *  https://github.com/trustwallet/assets/tree/master/blockchains/polygon
 *
 * Ethereum assets
 *  https://github.com/trustwallet/assets/tree/master/blockchains/ethereum
 *
 * Alternative Repo
 *  https://github.com/Uniswap/assets/tree/master/blockchains/ethereum
 *
 * Alternative https://etherscan.io/token/images/idex_32.png
 *
 * https://assets.coingecko.com/coins/images/15585/small/convex.png
 */
export function getCoinIcon(symbol: string) {
  switch (symbol) {
    case 'AAVE':
      return AAVE;
    case 'BIFI':
      return BIFI;
    case 'BNB':
    case 'WBNB':
      return BNB;
    case 'CRV':
      return CRV;
    case 'CVX':
      return CVX;
    case 'DAI':
      return DAI;
    case 'ETH':
    case 'WETH':
      return ETH;
    case 'FRAX':
      return FRAX;
    case 'FXS':
      return FXS;
    case 'GHST':
      return GHST;
    case 'IDEX':
      return IDEX;
    case 'LINK':
      return LINK;
    case 'MAI':
      return MAI;
    case 'MANA':
      return MANA;
    case 'MATIC':
      return MATIC;
    case 'SX':
      return SX;
    case 'SYN':
      return SYN;
    case 'USDT':
      return USDT;
    case 'USDC':
      return USDC;
    case 'WBTC':
    case 'BTC':
      return WBTC;
    case 'QUICK':
      return QUICK;
    case 'SUSHI':
      return SUSHI;
    case 'KNC':
      return KNC;
    case 'UNI':
      return UNI;
    case 'QI':
      return QI;
    case 'DG':
      return DG;
    case 'ALGB':
      return ALGB;
    // ADD_NEW_CASE_PLACEHOLDER
    default:
      return unknown;
  }
}
