import BigNumber from 'bignumber.js';
import erc20ABI from 'config/abi/erc20.json';
import {
  masterChefIdexABI,
  masterchefMultiRewardsABI,
} from 'config/abi/masterchefAbi';
import multicall from 'utils/multicall';
import config, { farmsConfig } from 'config/constants';

export const fetchFarmUserAllowances = async (
  account: string
): Promise<string[]> => {
  const calls = farmsConfig.map(farm => {
    const { lpAddress } = farm;
    return {
      address: lpAddress,
      name: 'allowance',
      params: [account, farm.farmContract.contractAddress],
    };
  });

  const rawLpAllowances = await multicall(erc20ABI, calls);
  const parsedLpAllowances = rawLpAllowances.map(lpBalance => {
    return new BigNumber(lpBalance).toJSON();
  });
  return parsedLpAllowances;
};

export const fetchFarmUserTokenBalances = async (
  account: string
): Promise<string[]> => {
  const calls = farmsConfig.map(farm => {
    const { lpAddress } = farm; // getAddress(farm.lpAddresses);
    return {
      address: lpAddress,
      name: 'balanceOf',
      params: [account],
    };
  });
  // console.log('USER_TOKEN: calls', calls);
  const rawTokenBalances = await multicall(erc20ABI, calls);

  const parsedTokenBalances = rawTokenBalances.map(tokenBalance => {
    // console.log('USER_TOKEN: USER BALANCE', tokenBalance);
    return new BigNumber(tokenBalance).toJSON();
  });
  return parsedTokenBalances;
};

export const fetchFarmUserStakedBalances = async (
  account: string
): Promise<string[]> => {
  const calls = farmsConfig.map(farm => {
    return {
      address: farm.farmContract.contractAddress,
      name: 'userInfo',
      params: [farm.pid, account],
    };
  });

  const rawStakedBalances = await multicall(masterChefIdexABI, calls);
  const parsedStakedBalances = rawStakedBalances.map(stakedBalance => {
    return new BigNumber(stakedBalance[0]._hex).toJSON();
  });
  return parsedStakedBalances;
};

type EarningPair = [string, string | undefined];

export const fetchFarmUserEarnings = async (
  account: string
): Promise<EarningPair[]> => {
  const allData = await Promise.all(
    config.farmsContracts.map(async farmContract => {
      const { contractAddress } = farmContract;
      const filteredFarms = farmsConfig.filter(
        farm => farm.farmContract.contractAddress === contractAddress
      );
      const calls = filteredFarms.map(farm => ({
        address: contractAddress,
        name: 'pendingReward',
        params: [farm.pid, account],
      }));
      const rawResults = await multicall(
        farmContract.contractAbi === 'singleReward'
          ? masterChefIdexABI
          : masterchefMultiRewardsABI,
        calls
      );
      const parsedEarnings: EarningPair[] = rawResults.map(earnings => {
        return earnings.length === 2
          ? [
              // Multi reward response
              new BigNumber(earnings[0].toString()).toJSON(),
              new BigNumber(earnings[1].toString()).toJSON(),
            ]
          : // Single reward response
            [new BigNumber(earnings).toJSON(), undefined];
      });
      const farmsResults = filteredFarms.map((farm, index) => ({
        pid: farm.pid,
        earnings: parsedEarnings[index],
      }));
      return { contractAddress, farmsResults };
    })
  );
  // Sort to response for all farms by index
  const parsedEarnings: EarningPair[] = farmsConfig.map(configFarm => {
    const targetData = allData.find(
      data => data.contractAddress === configFarm.farmContract.contractAddress
    );
    return (
      targetData?.farmsResults.find(farm => farm.pid === configFarm.pid)
        ?.earnings || ['0', undefined]
    );
  });
  return parsedEarnings;
};
