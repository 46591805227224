import { Box, Text } from '@idexio/dev-idex-swap-uikit';
import React from 'react';

import { RewardIcon } from './RewardsIcons';

type Props = {
  earnings: {
    symbol: string;
    earningInToken: number | undefined;
    earningUsd: number | undefined;
  }[];
};

export default function EarningsDetails({ earnings }: Props) {
  return (
    <>
      {earnings.map(({ earningInToken, earningUsd, symbol }, index) => {
        const isLast = index === earnings.length - 1;
        return (
          <Box pt="0" px="2" pb={isLast ? '0' : '2'} key={symbol}>
            <Text fontSize="1" textAlign="right" p="0">
              <RewardIcon size="sm" symbol={symbol} marginRight={8} />
              {earningInToken?.toFixed(3)} {symbol}
            </Text>
            <Text textAlign="right" color="textSubtle" p="0">
              ~${earningUsd?.toFixed(2)}
            </Text>
          </Box>
        );
      })}
    </>
  );
}
