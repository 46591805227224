import React, { useState } from 'react';
import { Box, Button, Flex, Text, Tooltip } from '@idexio/dev-idex-swap-uikit';

import SpinningRoundedBorder from 'components/SpinningRoundedBorder/SpinningRoundedBorder';
import { useHarvest } from 'hooks/useHarvest';
import { useToast } from 'state/hooks';
import { Farm } from 'state/types';
import { CardBusdValue2 } from '../CardBusdValue';
import RewardsIcons from './RewardsIcons';
import EarningsDetails from './EarningsDetails';
import RewardsInfoIcon from './RewardsInfoIcon';
import { Label as ColumnBoxLabel } from './ColumnBox';

interface FarmCardActionsProps {
  farm: Farm;
  earningsUsdTotal: number | undefined;
  earnings1: number | undefined;
  earnings2: number | undefined;
  earnings1Usd: number | undefined;
  earnings2Usd: number | undefined;
  isMobile: boolean;
}

const HarvestAction: React.FC<FarmCardActionsProps> = ({
  farm,
  earningsUsdTotal,
  earnings1,
  earnings2,
  earnings1Usd,
  earnings2Usd,
  isMobile,
}) => {
  const [pendingTx, setPendingTx] = useState(false);
  const { toastError } = useToast();
  const { onReward } = useHarvest(farm);

  const harvestButton = (
    <Button
      disabled={earningsUsdTotal === 0 || pendingTx}
      marginLeft={isMobile ? '3' : '2'}
      mb="2"
      endIcon={
        pendingTx ? (
          <Box ml="1">
            <SpinningRoundedBorder inline size={24} />
          </Box>
        ) : undefined
      }
      onClick={async () => {
        setPendingTx(true);
        try {
          await onReward();
        } catch (error) {
          console.warn(error);
          toastError(error?.message);
        }
        setPendingTx(false);
      }}
    >
      Harvest
    </Button>
  );

  const tooltipContent = (
    <EarningsDetails
      earnings={
        farm.farmContract.rewardToken2
          ? [
              {
                earningUsd: earnings1Usd,
                earningInToken: earnings1,
                symbol: farm.farmContract.rewardToken1.symbol,
              },
              {
                earningUsd: earnings2Usd,
                earningInToken: earnings2,
                symbol: farm.farmContract.rewardToken2.symbol,
              },
            ]
          : [
              {
                earningUsd: earnings1Usd,
                earningInToken: earnings1,
                symbol: farm.farmContract.rewardToken1.symbol,
              },
            ]
      }
    />
  );

  const rewards = (
    <Tooltip content={tooltipContent} position={isMobile ? 'upToRight' : 'up'}>
      <Flex alignItems="baseline">
        <RewardsIcons farm={farm} />
        <Text fontSize="4" color="primaryLight" mt="2" ml="1" mb="2">
          {earningsUsdTotal === undefined ? (
            <Text>
              {/* Just to align icons with text */}
              &nbsp;
            </Text>
          ) : (
            <CardBusdValue2 value={earningsUsdTotal || 0} />
          )}
        </Text>
      </Flex>
    </Tooltip>
  );

  return isMobile ? (
    <Flex justifyContent="space-between" mt="3">
      <Box pb="3">
        <ColumnBoxLabel>
          REWARDS{' '}
          <RewardsInfoIcon
            farmContract={farm.farmContract}
            earnings1={earnings1}
            earnings1Usd={earnings1Usd}
            earnings2={earnings2}
            earnings2Usd={earnings2Usd}
            isMobile={isMobile}
          />
        </ColumnBoxLabel>
        {rewards}
      </Box>
      <Box>{harvestButton}</Box>
    </Flex>
  ) : (
    // Desktop
    <Flex
      flexWrap="wrap"
      pr={isMobile ? 0 : '3'}
      py={isMobile ? '2' : 0}
      justifyContent={isMobile ? 'flex-end' : 'space-between'}
    >
      {rewards}
      {harvestButton}
    </Flex>
  );
};

export default HarvestAction;
