import { useCallback } from 'react';
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core';
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector';
import {
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
  WalletConnectConnector,
} from '@web3-react/walletconnect-connector';
import {
  ConnectorNames,
  connectorLocalStorageKey,
} from '@idexio/dev-idex-swap-uikit';
import { useToast } from 'state/hooks';
import { connectorsByName } from 'utils/web3React';
import { setupNetwork } from 'utils/wallet';

const useAuth = () => {
  const { activate, deactivate } = useWeb3React();
  const { toastError } = useToast();

  const login = useCallback((connectorID: ConnectorNames) => {
    const connector = connectorsByName[connectorID];
    if (!connector) {
      toastError("Can't find connector", 'The connector config is wrong');
    }
    activate(connector, async (error: Error) => {
      if (error instanceof UnsupportedChainIdError) {
        const hasSetup = await setupNetwork();
        if (hasSetup) {
          activate(connector);
        }
        return;
      }
      window.localStorage.removeItem(connectorLocalStorageKey);
      if (error instanceof NoEthereumProviderError) {
        toastError('Provider Error', 'No provider was found');
      } else if (
        error instanceof UserRejectedRequestErrorInjected ||
        error instanceof UserRejectedRequestErrorWalletConnect
      ) {
        if (connector instanceof WalletConnectConnector) {
          const walletConnector = connector as WalletConnectConnector;
          walletConnector.walletConnectProvider = undefined;
        }
        toastError(
          'Authorization Error',
          'Please authorize to access your account'
        );
      } else {
        toastError(error.name, error.message);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = useCallback(() => {
    deactivate();
    // This localStorage key is set by @web3-react/walletconnect-connector
    if (window.localStorage.getItem('walletconnect')) {
      connectorsByName.walletconnect.close();
      connectorsByName.walletconnect.walletConnectProvider = null;
    }
    window.localStorage.removeItem(connectorLocalStorageKey);
    // if (chainId) {
    //   dispatch(clearAllTransactions({ chainId }))
    // }
  }, [deactivate]);

  return { login, logout };
};

export default useAuth;
