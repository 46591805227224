import { RestPublicClient } from '@idexio/idex-sdk';
import config from 'config/constants';
import { handleGeoResponse, patchClientToIdexInternal } from './geoRestricted';

const restApi = new RestPublicClient({
  baseURL: config.restApiUrl,
  chain: config.chain,
  sandbox: config.restApiSandboxFlag,
});

patchClientToIdexInternal(restApi as any);

export const fetchExchangeInfo = () => restApi.getExchangeInfo();
export const fetchAssets = () => restApi.getAssets();
export const fetchLiquidityPools = () => restApi.getLiquidityPools();

const fetchIdexApi = async <RETURN>(path: string): Promise<RETURN> =>
  fetch(`${config.restApiUrl}${path}`, {
    headers: {
      'IDEX-Web-Client': `farms-${process.env.REACT_APP_RELEASE_VERSION}`,
    },
  })
    .then(handleGeoResponse)
    .then(response => response.json());

// const postIdexApi = async <RETURN>(path: string, params: any): Promise<RETURN> =>
//   fetch(`${config.restApiUrl}${path}`, {
//     method: 'POST',
//     headers: {
//       'IDEX-Web-Client': `farms-${process.env.REACT_APP_RELEASE_VERSION}`,
//       body: JSON.stringify(params)
//     },
//   })
//     .then(handleGeoResponse)
//     .then(response => response.json());

export const fetchInternalStats = () =>
  fetchIdexApi<{
    liquidityProviderFees24hUsd: {
      [market: string]: string;
    };
  }>('/internal/stats');

export const fetchInternalWalletScreening = (
  wallet: string
): Promise<string | false> =>
  fetch(`${config.restApiUrl}/internal/walletScreening?wallet=${wallet}`, {
    headers: {
      'IDEX-Web-Client': `farms-${process.env.REACT_APP_RELEASE_VERSION}`,
    },
  })
    .then(handleGeoResponse)
    .then(response => {
      if (response.status === 451) {
        return response
          .json()
          .then(errorResponse => errorResponse?.message || 'Wallet blocked');
      }
      // if (response.status !== 200) {
      //   return 'Unknown error, please try again later or contact support';
      // }
      return false;
    });
