import { Box } from '@idexio/dev-idex-swap-uikit';
import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components/macro';

const Separator = styled.div`
  position: absolute;
  width: 1px;
  height: calc(100% - 28px);
  right: 0;
  top: 14px;
  background: #272727;
`;

const Column = styled.div<{ isMobile: boolean; isLast: boolean }>`
  position: relative;
  padding: ${({ isMobile }) => (isMobile ? '0 24px 0 24px' : '0 12px 0 24px')};
  padding-bottom: ${({ isLast }) => (isLast ? '8px' : '0')};
`;

const bottomMobileBorder = css<{ isMobile: boolean; isLast: boolean }>`
  ${({ isMobile, isLast }) =>
    isMobile && !isLast && 'border-bottom: 1px solid #272727;'}
`;

const ColumnContent = styled.div<{ isMobile: boolean; isLast: boolean }>`
  ${bottomMobileBorder}

  ${({ isMobile }) =>
    isMobile &&
    `
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`;

export const Label = styled(Box)`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: #a1a1a1;
  white-space: nowrap;
`;

const Value = styled.div<{ middlePadding?: boolean }>`
  font-style: normal;
  font-size: 20px;
  color: #ffffff;
  vertical-align: bottom;
  ${({ middlePadding }) => middlePadding && 'padding-top: 6px;'}
`;

const ChildrenOnlyValue = styled.div`
  ${bottomMobileBorder}
`;

export default function ColumnBox({
  title,
  children,
  isMobile,
  middlePadding,
  isLast,
}: {
  title?: ReactNode;
  children: ReactNode;
  isMobile: boolean;
  middlePadding?: boolean;
  isLast?: boolean;
}) {
  return (
    <Column isMobile={isMobile} isLast={!!isLast}>
      {title ? (
        <ColumnContent isMobile={isMobile} isLast={!!isLast}>
          <Label m="18px 0 14px 0">{title}</Label>
          <Value middlePadding={isMobile ? false : middlePadding}>
            {children}
          </Value>
        </ColumnContent>
      ) : (
        <ChildrenOnlyValue isMobile={isMobile} isLast={!!isLast}>
          {children}
        </ChildrenOnlyValue>
      )}
      {!isMobile && !isLast && <Separator />}
    </Column>
  );
}
