import { useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Contract } from 'web3-eth-contract';
import { useAppDispatch } from 'state';
import { fetchFarmUserDataAsync } from 'state/farms';
import { approve } from 'utils/callHelpers';
import { FarmContract } from 'config/constants/types';

export const useApproveFarm = (
  farmContract: FarmContract,
  lpContract: Contract
) => {
  const dispatch = useAppDispatch();
  const { account } = useWeb3React();
  const { contractAddress } = farmContract;

  const handleApprove = useCallback(async () => {
    try {
      const tx = await approve(lpContract, contractAddress, account);
      dispatch(fetchFarmUserDataAsync(account));
      return tx;
    } catch (error) {
      console.warn(error);
      return false;
    }
  }, [account, dispatch, lpContract, contractAddress]);

  return { onApprove: handleApprove };
};
