import React, { useMemo } from 'react';
import {
  Text,
  Flex,
  useMatchBreakpoints,
  Tooltip,
  HelpIcon,
  // Link,
} from '@idexio/dev-idex-swap-uikit';
import styled from 'styled-components/macro';

import { BASE_LIQUIDITY_URL } from 'config';
import { useFetchTotalStats } from 'hooks/useTotalStats';
import { useApiPrices, useExchangeInfo, useFarms } from 'state/hooks';
import { Token } from 'config/constants/types';
import { DEBUG_ENABLED } from 'config/constants/environments';
import { useIsRestricted } from 'components/RestrictedBanner';
// import IdexTokenWalletBalance from 'views/Farms/components/IdexTokenWalletBalance';
// import EarnAPYCard from 'views/Home/components/EarnAPYCard'
import HarvestBox from './HarvestBox';
import exchangeIcon from './icons/exchange.svg';
import unlockIcon from './icons/unlock.svg';
// import walletIcon from './icons/wallet.svg';
import worldIcon from './icons/world.svg';
import databaseAddIcon from './icons/databaseAdd.svg';
import EarningsDetails from '../FarmRow/EarningsDetails';

const Icon = styled.img`
  width: 32px;
  height: 32px;
`;

const Label = styled.div`
  padding: 8px 0 8px 0;
  text-transform: uppercase;
`;

const Box = styled.div<{ flex?: string }>`
  padding: 24px;
  flex: ${({ flex }) => flex || 'auto'};
  background: #06060b;
  border: 1px solid #272727;

  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.primaryAlt};
  ${({ theme }) => theme.mediaQueries.lg} {
    &:nth-last-child(1) {
      border-right: none;
    }
  }
`;

const MobileBox = styled(Box)`
  flex: 1;
`;

const ActionBoxLink = styled.a<{ disabled: boolean }>`
  display: block;
  flex: initial;
  margin: 13px 0 13px 32px;
  padding: 24px 32px;
  box-shadow: 0px 0px 60px #8247e566;
  background: ${({ theme }) => theme.colors.primary};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: ${({ disabled }) => (disabled ? '0.5' : '0.9')};
  }
  &:active {
    opacity: ${({ disabled }) => (disabled ? '0.5' : '0.8')};
  }
  @media (min-width: 953px) and (max-width: 1125px) {
    display: none;
  }
`;

const BoxValue = styled.div<{ main?: boolean }>`
  font-weight: normal;
  font-size: ${({ main }) => (main ? '32px' : '24px')};
  ${({ theme }) => theme.mediaQueries.md} {
    font-size: ${({ main }) => (main ? '40px' : '24px')};
  }

  line-height: 1.5;
  color: #ffffff;
`;

const GetLpActionBox = () => {
  const isRestricted = useIsRestricted();
  return (
    <ActionBoxLink
      href={isRestricted ? '#' : BASE_LIQUIDITY_URL}
      disabled={isRestricted}
    >
      <img src={databaseAddIcon} alt="Get LP" />
      <Text pt="2" color="white" bold>
        Get IDEX LP
      </Text>
    </ActionBoxLink>
  );
};

const StatsBoxes: React.FC = () => {
  const { breakpointEqualOrSmallerThan, breakpointEqualOrLargerThan } =
    useMatchBreakpoints();
  const exchangeInfo = useExchangeInfo();
  const isMedium = breakpointEqualOrSmallerThan('lg');
  const isMobile = breakpointEqualOrSmallerThan('md');
  const isLargest = breakpointEqualOrLargerThan('lg');

  const farmsWithData = useFarms();
  const apiPrices = useApiPrices();

  const totalStats = useFetchTotalStats();
  const totalValueLocked =
    totalStats && totalStats.totalValueLockedUsd
      ? totalStats.totalValueLockedUsd.toLocaleString('en-US', {
          maximumFractionDigits: 0,
        })
      : null;
  const idexMarketCapUsd = totalStats
    ? totalStats.idexMarketCapUsd.toLocaleString('en-US', {
        maximumFractionDigits: 0,
      })
    : null;

  const farmsWithPendingRewards = useMemo(
    () =>
      farmsWithData.filter(farm => {
        const { earnings1, earnings2 } = farm.userData || {};
        return (earnings1 && earnings1 > 0) || (earnings2 && earnings2 > 0);
      }),
    [farmsWithData]
  );

  const tokenEarningsMap = useMemo(() => {
    if (
      !apiPrices ||
      Object.keys(apiPrices).length === 0 ||
      !farmsWithData ||
      farmsWithData.every(farm => !farm.userData)
    ) {
      // Loading
      return undefined;
    }
    return farmsWithData.reduce(
      (earningsMap, farmData) => {
        const token1Address =
          farmData.farmContract.rewardToken1.address.toLowerCase();
        const token2Address =
          farmData.farmContract.rewardToken2?.address?.toLowerCase();
        const token1Price = apiPrices[token1Address];
        const token2Price = token2Address
          ? apiPrices[token2Address]
          : undefined;

        const calculateEarning = (
          rewardToken: Token | undefined,
          rewardTokenPrice: number | null | undefined,
          earnings: number | undefined = 0
        ) => {
          if (!rewardToken) {
            return;
          }
          const earningsUsd = earnings * (rewardTokenPrice || 0);
          const existingEarning = earningsMap[rewardToken.symbol] || {};
          // eslint-disable-next-line no-param-reassign
          earningsMap[rewardToken.symbol] = {
            rewardTokenPrice,
            earningsToken: (existingEarning.earningsToken || 0) + earnings,
            earningsUsd: (existingEarning.earningsUsd || 0) + earningsUsd,
          };
        };
        calculateEarning(
          farmData.farmContract.rewardToken1,
          token1Price,
          farmData.userData?.earnings1
        );
        calculateEarning(
          farmData.farmContract.rewardToken2,
          token2Price,
          farmData.userData?.earnings2
        );
        return earningsMap;
      },
      {} as {
        [tokenSymbol: string]: {
          rewardTokenPrice?: number | null;
          earningsToken: number;
          earningsUsd: number;
        };
      }
    );
  }, [farmsWithData, apiPrices]);

  const tokenEarnings = useMemo(() => {
    if (!tokenEarningsMap) {
      return undefined;
    }
    return Object.entries(tokenEarningsMap).map(([symbol, earnings]) => ({
      ...earnings,
      symbol,
    }));
  }, [tokenEarningsMap]);

  const totalEarningsUsd = useMemo(
    () =>
      tokenEarnings
        ? tokenEarnings.reduce((sum, earning) => sum + earning.earningsUsd, 0)
        : undefined,
    [tokenEarnings]
  );

  const totalEarningsTokenSymbols = useMemo(() => {
    const symbols = tokenEarnings?.map(earning => earning.symbol);
    return symbols
      ? symbols.filter((item, pos) => {
          // Remove duplicities
          return symbols.indexOf(item) === pos;
        })
      : undefined;
  }, [tokenEarnings]);

  if (isMedium) {
    return (
      <Flex pt={32} pb={18} justifyContent="center">
        <MobileBox>
          <Icon src={unlockIcon} />
          <Label>Farms Total Value Locked (TVL)</Label>
          <BoxValue main>
            {totalValueLocked ? `$${totalValueLocked}` : null}
          </BoxValue>
        </MobileBox>
        {!isMobile && <GetLpActionBox />}
      </Flex>
    );
  }

  const earningsModalContent = tokenEarnings ? (
    <EarningsDetails
      earnings={tokenEarnings.map(earning => ({
        earningUsd: earning.earningsUsd,
        earningInToken: earning.earningsToken,
        symbol: earning.symbol,
      }))}
    />
  ) : undefined;

  return (
    <Flex pt={32} pb={18}>
      <Box flex="auto">
        <Icon src={unlockIcon} />
        <Label>Farms Total Value Locked (TVL)</Label>
        <BoxValue main>
          {totalValueLocked ? `$${totalValueLocked}` : null}
        </BoxValue>
        {DEBUG_ENABLED && (
          <div style={{ color: 'yellow' }}>
            EXCHANGE INFO
            <br />
            maticUsdPrice: ${exchangeInfo?.maticUsdPrice}
          </div>
        )}
      </Box>
      {/* <Box>
        <Icon src={walletIcon} />
        <Label>IDEX in Wallet</Label>
        <BoxValue>
          <IdexTokenWalletBalance />
          <Link
            mt="3"
            target="_blank"
            href="https://exchange.idex.io/rewards/staking/replicator"
          >
            Stake IDEX to earn {NATIVE_ASSET_SYMBOL}
          </Link>
        </BoxValue>
      </Box> */}
      <Box flex="1">
        <Icon src={exchangeIcon} />
        <Label>
          <Flex>
            <div>Rewards</div>
            {earningsModalContent && (
              <Tooltip content={earningsModalContent} position="right">
                <HelpIcon
                  color="primaryAlt"
                  width="17px"
                  mb="-4px"
                  mt="-4px"
                  ml="4px"
                />
              </Tooltip>
            )}
          </Flex>
        </Label>
        <BoxValue>
          <HarvestBox
            earningsModalContent={earningsModalContent}
            totalEarningsUsd={totalEarningsUsd}
            totalEarningsTokenSymbols={totalEarningsTokenSymbols || []}
            farmsWithPendingRewards={farmsWithPendingRewards}
          />
        </BoxValue>
      </Box>
      {isLargest && (
        <Box flex="1">
          <Icon src={worldIcon} />
          <Label>IDEX Market cap</Label>
          <BoxValue>${idexMarketCapUsd}</BoxValue>
        </Box>
      )}
      {/* <Box>
        <EarnAPYCard />
      </Box> */}
      <GetLpActionBox />
    </Flex>
  );
};

export default StatsBoxes;
