export const sortOptions = [
  'Default',
  'Name',
  'APR',
  'Multiplier',
  'Liquidity',
  'Staked',
  'Rewards',
] as const;

type SortType = typeof sortOptions[number]; // `${SortOptions}`;

export type ControlsState = {
  sortOption?: SortType;
  query: string;
  stakedOnly: boolean;
};

export const controlsInitialState: ControlsState = {
  sortOption: undefined,
  query: '',
  stakedOnly: false,
};

// Actions
export const changeSortOption = (option: SortType) =>
  ({ type: 'changeSortOption', option } as const);

export const changeQuery = (query: string) =>
  ({ type: 'changeQuery', query } as const);

export const changeStakedOnly = (stakedOnly: boolean) =>
  ({ type: 'changeSwakedOnly', stakedOnly } as const);

export type ControlsAction = ReturnType<
  typeof changeSortOption | typeof changeQuery | typeof changeStakedOnly
>;

export function controlsReducer(
  state: ControlsState,
  action: ControlsAction
): ControlsState {
  switch (action.type) {
    case 'changeQuery':
      return { ...state, query: action.query };
    case 'changeSortOption':
      return { ...state, sortOption: action.option };
    case 'changeSwakedOnly':
      return { ...state, stakedOnly: action.stakedOnly };
    default:
      throw new Error('Unknown farmControls reducer action');
  }
}
