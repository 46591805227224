/* eslint-disable no-console */
// import { RestPublicClient } from '@idexio/idex-sdk';
import { useEffect } from 'react';

import {
  fetchAssets,
  // /* configProduction, */ configMumbaiTestnet,
  fetchExchangeInfo,
} from './index';
import { tokens } from '../config/constants';

export default function DevTokens() {
  const run = async () => {
    // const productionRestApi = new RestPublicClient({
    //   baseURL: configProduction.restApiUrl,
    //   chain: configProduction.chain,
    //   sandbox: configMumbaiTestnet.sandboxFlag,
    // });
    fetchExchangeInfo().then(response => {
      console.log(
        'EXCHANGE CONTRACT (Polygon):',
        response.maticDepositContractAddress
      );
    });

    // matic: {
    //   symbol: 'MATIC',
    //   address: {
    //     137: ethers.constants.AddressZero,
    //     80001: ethers.constants.AddressZero,
    //   },
    //   decimals: 18,
    //   projectLink: 'https://polygon.technology/',
    // },
    // const prodTokens = await productionRestApi.getAssets();
    const testnetTokens = await fetchAssets();

    const tokenSymbols = new Set(
      testnetTokens.map(token => token.symbol)
      // prodTokens
      //   .map(token => token.symbol)
      //   .concat(testnetTokens.map(token => token.symbol))
    );

    const findToken = (symbol: string) => {
      const prodToken: any = undefined; // prodTokens.find(token => token.symbol === symbol);
      const testnetToken = testnetTokens.find(token => token.symbol === symbol);
      return {
        prodToken,
        testnetToken,
      };
    };

    const findTokenAddresses = (symbol: string) => {
      const { prodToken, testnetToken } = findToken(symbol);
      if (!prodToken) {
        return { 80001: testnetToken?.contractAddress };
      }
      if (!testnetToken) {
        return { 137: prodToken?.contractAddress };
      }
      return {
        137: prodToken?.contractAddress,
        80001: testnetToken?.contractAddress,
      };
    };

    const map = {
      matic: {
        symbol: 'MATIC',
        address: {
          137: 'ethers.constants.AddressZero',
          80001: 'ethers.constants.AddressZero',
        },
        decimals: findToken('MATIC').prodToken?.decimals || 18,
        projectLink: 'https://polygon.technology/',
      },
      idex: {
        symbol: 'IDEX',
        address: findTokenAddresses('IDEX'),
        projectLink: 'https://idex.io/',
        decimals: findToken('IDEX').prodToken?.decimals,
      },
    };
    tokenSymbols.delete('MATIC');
    tokenSymbols.forEach(symbol => {
      const { prodToken, testnetToken } = findToken(symbol);
      if (prodToken?.assetDecimals !== testnetToken?.assetDecimals) {
        console.warn(
          `Decimals not same between production and testnet for ${symbol}, using production decimals`
        );
      }
      map[symbol.toLowerCase()] = {
        symbol,
        address: findTokenAddresses(symbol),
        decimals: (prodToken || testnetToken)?.assetDecimals || '???',
        projectLink: tokens[symbol.toLowerCase()]?.projectLink,
      };
    });

    // eslint-disable-next-line no-console
    console.log(JSON.stringify(map, null, 4));
  };
  useEffect(() => {
    run();
  }, []);
  return null;
}
