import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchExchangeInfo } from 'api';

/*
 * Due to Cors the api was forked and a proxy was created
 * @see https://github.com/pancakeswap/gatsby-pancake-api/commit/e811b67a43ccc41edd4a0fa1ee704b2f510aa0ba
 */
// export const baseUrl = 'https://api.pancakeswap.com/api/v1'

/* eslint-disable camelcase */
export interface ExchangeInfo {
  updateAt: string;
  totalVolume24hUsd: number;
  totalValueLockedUsd: number;
  idexMarketCapUsd: number;
  maticUsdPrice: number;
}

export type ExchangeInfoState = {
  data: ExchangeInfo | null;
};

const initialState: ExchangeInfoState = { data: null };

export const fetchAndDispatchExchangeInfo =
  createAsyncThunk<ExchangeInfo | null>('exchangeInfo/fetch', async () => {
    try {
      const {
        serverTime,
        volume24hUsd,
        totalValueLockedUsd,
        idexMarketCapUsd,
        maticUsdPrice,
      } = await fetchExchangeInfo();
      return {
        updateAt: new Date(serverTime).toISOString(),
        totalVolume24hUsd: Number(volume24hUsd),
        totalValueLockedUsd: Number(totalValueLockedUsd),
        idexMarketCapUsd: Number(idexMarketCapUsd),
        maticUsdPrice: Number(maticUsdPrice),
      };
    } catch (error) {
      console.error('Unable to fetch data:', error);
      return null;
    }
  });

export const exchangeInfo = createSlice({
  name: 'exchangeInfo',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // builder.addCase(fetchAndDispatchExchangeInfo.pending, state => {
    // state.isLoading = true;
    // state =
    // });
    (builder as any).addCase(
      fetchAndDispatchExchangeInfo.fulfilled,
      (state, action: PayloadAction<ExchangeInfo>) => {
        // eslint-disable-next-line no-param-reassign
        state.data = action.payload;
      }
    );
  },
});

export default exchangeInfo.reducer;
