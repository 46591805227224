import React, { useCallback, useMemo } from 'react';
import { ThemeProvider as SCThemeProvider } from 'styled-components/macro';
import { light, dark } from '@idexio/dev-idex-swap-uikit';

// const CACHE_KEY = 'IS_DARK'

const ThemeContext = React.createContext<{
  isDark: boolean | null;
  toggleTheme: () => void;
}>({
  isDark: null,
  toggleTheme: () => null,
});

const ThemeContextProvider = ({ children }) => {
  // const [isDark, setIsDark] = useState(() => {
  //   const isDarkUserSetting = localStorage.getItem(CACHE_KEY)
  //   return isDarkUserSetting ? JSON.parse(isDarkUserSetting) : false
  // })

  // const toggleTheme = () => {
  //   setIsDark((prevState) => {
  //     localStorage.setItem(CACHE_KEY, JSON.stringify(!prevState))
  //     return !prevState
  //   })
  // }
  const isDark = true;
  const toggleTheme = useCallback(() => {
    // nothing..
  }, []);

  const themeProps = useMemo(
    () => ({
      isDark,
      toggleTheme,
    }),
    [isDark, toggleTheme]
  );

  return (
    <ThemeContext.Provider value={themeProps}>
      <SCThemeProvider theme={isDark ? dark : light}>
        {children}
      </SCThemeProvider>
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeContextProvider };
