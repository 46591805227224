import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface RestrictedState {
  restrictedCountry?: string;
  restrictedWallet?: string | false;
  restrictedWalletMessage?: string | false;
}

const initialState: RestrictedState = {
  restrictedCountry: undefined,
  restrictedWallet: undefined,
  restrictedWalletMessage: '',
};

const restrictedReducer = createSlice({
  name: 'restricted',
  initialState,
  reducers: {
    addGeoblockedCountry: (state, action: PayloadAction<string>) => {
      // eslint-disable-next-line no-param-reassign
      state.restrictedCountry = action.payload;
    },
    addBlockedWallet: (
      state,
      action: PayloadAction<{ wallet: string; reason: string }>
    ) => {
      // eslint-disable-next-line no-param-reassign
      state.restrictedWallet = action.payload.wallet.toLocaleLowerCase();
      // eslint-disable-next-line no-param-reassign
      state.restrictedWalletMessage = action.payload.reason;
    },
    addUnrestrictedBlockedWallet: (
      state,
      action: PayloadAction<{ wallet: string; reason: string }>
    ) => {
      // eslint-disable-next-line no-param-reassign
      state.restrictedWallet = action.payload.wallet.toLocaleLowerCase();
      // eslint-disable-next-line no-param-reassign
      state.restrictedWalletMessage = action.payload.reason;
    },
    addNotBlockedWallet: state => {
      // eslint-disable-next-line no-param-reassign
      state.restrictedWallet = false;
      // eslint-disable-next-line no-param-reassign
      state.restrictedWalletMessage = '';
    },
  },
});

export const { addGeoblockedCountry, addBlockedWallet, addNotBlockedWallet } =
  restrictedReducer.actions;

export default restrictedReducer.reducer;
