import React from 'react';
import {
  IconButton,
  useModal,
  CalculateIcon,
  Box,
} from '@idexio/dev-idex-swap-uikit';
import ApyCalculatorModal from './ApyCalculatorModal';

export interface ApyButtonProps {
  lpLabel?: string;
  apy?: number;
  addLiquidityUrl?: string;
}

const ApyButton: React.FC<ApyButtonProps> = ({
  lpLabel,
  apy,
  addLiquidityUrl,
}) => {
  const [onPresentApyModal] = useModal(
    <ApyCalculatorModal
      lpLabel={lpLabel}
      apy={apy}
      addLiquidityUrl={addLiquidityUrl}
    />
  );

  const handleClickButton = (event): void => {
    event.stopPropagation();
    onPresentApyModal();
  };

  return (
    <Box display="inline-block" mt="-20px" position="relative" top="4px">
      <IconButton onClick={handleClickButton} variant="text" customScale="22px">
        <CalculateIcon width="100%" color="primaryLight" />
      </IconButton>
    </Box>
  );
};

export default ApyButton;
