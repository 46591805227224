import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';

import { LP_DECIMALS } from 'config/constants/farms';
import { fetchCurrentPolygonGasInWei } from 'api/apiGasStation';

export const approve = async (
  lpContract,
  masterChefContractAddress: string,
  account: string | null | undefined
) => {
  const gasPrice = await fetchCurrentPolygonGasInWei();
  return lpContract.methods
    .approve(masterChefContractAddress, ethers.constants.MaxUint256)
    .send({ from: account, gasLimit: 200000, gasPrice });
};

// https://github.com/pancakeswap/pancake-farm/blob/master/contracts/MasterChef.sol#L272
// https://github.com/idexio/idex-farm/blob/dev/contracts/IDEXFarm.sol#L199
export const stake = async (masterChefContract, pid, amount, account) => {
  const amountBN = new BigNumber(amount)
    .times(new BigNumber(10).pow(LP_DECIMALS))
    .toString();
  const gasPrice = await fetchCurrentPolygonGasInWei();
  return masterChefContract.methods
    .deposit(pid, amountBN)
    .send({ from: account, gasLimit: 200000, gasPrice })
    .once('transactionHash', tx => {
      return tx.transactionHash;
    });
};

export const unstake = async (masterChefContract, pid, amount, account) => {
  const gasPrice = await fetchCurrentPolygonGasInWei();
  return masterChefContract.methods
    .withdraw(
      pid,
      new BigNumber(amount).times(new BigNumber(10).pow(LP_DECIMALS)).toString()
    )
    .send({ from: account, gasLimit: 200000, gasPrice })
    .once('transactionHash', tx => {
      return tx.transactionHash;
    });
};

/**
 * Harvest wallet rewards.
 * 
 * Possible errors (silent errors, see in block explorer):
 * 
 *  - "safeRewardTokenTransfer: insufficient balance":
 *      - contract funding is not enough to cover TX

 */
export const harvest = async (masterChefContract, pid, account) => {
  const gasPrice = await fetchCurrentPolygonGasInWei();
  return masterChefContract.methods
    .deposit(pid, '0')
    .send({ from: account, gasLimit: 200000, gasPrice })
    .once('transactionHash', tx => {
      return tx.transactionHash;
    });
};
