/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchAssets } from 'api';

interface PriceApiListThunk {
  [key: string]: number | null;
}

interface PriceApiThunk {
  // eslint-disable-next-line camelcase
  updated_at: string;
  data: PriceApiListThunk;
}

interface PriceState {
  isLoading: boolean;
  lastUpdated: string | null;
  data: PriceApiListThunk | null;
}

const initialState: PriceState = {
  isLoading: false,
  lastUpdated: null,
  data: null,
};

export const fetchPrices = createAsyncThunk<
  PriceApiThunk,
  { maticPriceUsd: number }
>('prices/fetch', async ({ maticPriceUsd }: { maticPriceUsd: number }) => {
  const assets = await fetchAssets();
  const pricesMap = assets.reduce((map, asset) => {
    map[asset.contractAddress.toLowerCase()] = asset.maticPrice
      ? Number(asset.maticPrice) * Number(maticPriceUsd)
      : null;
    return map;
  }, {} as { [addr: string]: number | null });
  return {
    updated_at: new Date().toISOString(),
    data: pricesMap,
  };
});

export const pricesSlice = createSlice({
  name: 'prices',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchPrices.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchPrices.fulfilled,
      (state, action: PayloadAction<PriceApiThunk>) => {
        state.isLoading = false;
        state.lastUpdated = action.payload.updated_at;
        state.data = action.payload.data;
      }
    );
  },
});

export default pricesSlice.reducer;
