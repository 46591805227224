// https://github.com/idexio/pancake-farm/blob/dev/contracts/MasterChef.sol
// https://github.com/idexio/idex-farm
// https://github.com/idexio/idex-contracts-silverton
// https://bsc.kiemtienonline360.com/

import { devFarms, productionFarms, stagingFarms } from './farms';
import {
  devFarmContracts,
  productionFarmContracts,
  stagingFarmContracts,
} from './farmsContracts';
import { devTokens, productionTokens, stagingTokens } from './tokens';
import { Config } from './types';

export const DEBUG_ENABLED = document?.location?.search.includes('debug');

const STAGING_OVERRIDE = document?.location?.search.includes('staging');
const DEV1_OVERRIDE = document?.location?.search.includes('dev1');

export const NATIVE_ASSET_SYMBOL = 'MATIC';

// https://blog.polygon.technology/polygon-rpc-gateway-will-provide-a-free-high-performance-connection-to-the-polygon-pos-blockchain/
// https://docs.polygon.technology/docs/develop/network-details/network
export const production: Config = {
  chain: 'matic',

  chainId: 137,
  chainNetwork: 'mainnet-matic',
  chainRpc:
    'https://polygon-mainnet.infura.io/v3/66f626959a0c4b29bc4dcab51e2e39d5',
  // 'https://polygon-mainnet.g.alchemy.com/v2/5qFtMf3hJYtBJnh-yh0ETc9CMO64okFU',
  chainRpcSoftwareWallet: 'https://polygon-rpc.com/',

  chainBlockTime: 2.3,
  chainBlockExplorer: 'https://polygonscan.com',

  gasStationUrl: 'https://gasstation.polygon.technology/v2',

  exchangeUrl: 'https://exchange.idex.io',
  restApiUrl: 'https://api-matic.idex.io/v1',
  restApiSandboxFlag: false,
  // webSocketApiUrl: 'wss://websocket-matic.idex.io/v1',

  multiCallContract: '0x11ce4B23bD875D7F5C6a31084f55fDe1e9A87507',

  tokens: productionTokens,
  farms: productionFarms,
  farmsContracts: Object.values(productionFarmContracts),
};

const preProduction: Config = {
  ...production,
  exchangeUrl: 'https://client-staging.idex.io',
};

export const staging: Config = {
  chain: 'matic',

  chainId: 80001,
  chainNetwork: 'Mumbai Testnet',
  chainRpc:
    'https://polygon-mumbai.infura.io/v3/66f626959a0c4b29bc4dcab51e2e39d5',
  // 'https://polygon-mumbai.g.alchemy.com/v2/AQvF7X06a70rZ133zHTSLh7Rqg6-_YeT',
  chainRpcSoftwareWallet:
    'https://polygon-mumbai.infura.io/v3/66f626959a0c4b29bc4dcab51e2e39d5',

  chainBlockTime: 5,
  chainBlockExplorer: 'https://mumbai.polygonscan.com',

  gasStationUrl: 'https://gasstation-testnet.polygon.technology/v2',

  exchangeUrl: 'https://exchange-staging.idex-dev.com',
  restApiUrl: 'https://api-staging-sub-matic.idex-dev.com/v1',
  restApiSandboxFlag: true,
  // webSocketApiUrl: 'wss://websocket-staging-sub-matic.idex-dev.com/v1',

  multiCallContract: '0xaf932433978394aB1653D4295b234433F36B7fcd',

  tokens: stagingTokens,
  farms: stagingFarms,
  farmsContracts: Object.values(stagingFarmContracts),
};

export const unitTest: Config = {
  ...staging,
  chainBlockTime: 2.3,
};

export const dev1: Config = {
  ...staging,
  // exchangeUrl: 'http://exchange-local.idex-dev.com', // Test locally
  exchangeUrl: 'https://exchange-dev-1.idex-dev.com',
  restApiUrl: 'https://api-dev-sub-matic-1.idex-dev.com/v1',
  // webSocketApiUrl: 'wss://websocket-dev-sub-matic-1.idex-dev.com/v1',

  tokens: devTokens,
  farms: devFarms,
  farmsContracts: Object.values(devFarmContracts),
};

const hostname = window?.location?.host || '';

/** 
 * Add to /etc/hosts to easy localhost switch
 
127.0.0.1 farms-local.idex.io
127.0.0.1 farms-staging-local.idex-dev.com
127.0.0.1 farms-dev-1-local.idex-dev.com
 * 
*/
export const config: Config =
  process?.env?.IDEX_ENV === 'test'
    ? unitTest
    : STAGING_OVERRIDE
    ? staging
    : hostname === 'farms-staging.idex.io'
    ? preProduction
    : hostname === 'farms-staging.idex-dev.com' ||
      hostname.startsWith('farms-staging-local.idex-dev.com')
    ? staging
    : hostname === 'farms-dev-1.idex-dev.com' ||
      hostname.startsWith('localhost') ||
      hostname.startsWith('farms-dev-1-local.idex-dev.com') ||
      DEV1_OVERRIDE
    ? dev1
    : production;

// Make it accessible for easy environments debug
(window as any).globalConfig = config;
