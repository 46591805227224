import BigNumber from 'bignumber.js/bignumber';
import { getAppUrls } from '@idexio/dev-idex-swap-uikit';
import config from './constants';

export const NETWORK_CHAIN_ID = config.chainId;

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
});

// IDEX_PER_BLOCK details (NOTE: Might be out of date)
// 40 IDEX is minted per block
// 18 IDEX per block is sent to Burn pool (A farm just for burning IDEX)
// 10 IDEX per block goes to IDEX syrup pool
// 12 IDEX per block goes to Yield farms and lottery
// IDEX_PER_BLOCK in config/index.ts = 40 as we only change the amount sent to the burn pool which is effectively a farm.
// IDEX/Block in components/IDEXStats.tsx = 22 (40 - Amount sent to burn pool)

export const BLOCKS_PER_YEAR = new BigNumber(
  (60 / config.chainBlockTime) * 60 * 24 * 365
); // 10512000

const { farmsUrl } = getAppUrls();

export const BASE_URL = farmsUrl;
// export const BASE_EXCHANGE_URL = swapUrl;

export const NATIVE_ASSET_SYMBOL = 'MATIC';

export const BASE_LIQUIDITY_URL = `${config.exchangeUrl}/liquidity`;

export const BASE_ADD_LIQUIDITY_URL = `${BASE_LIQUIDITY_URL}/add`;
