import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Providers from './Providers';

// window.setTimeout(() => {
//   const warningTitleCSS =
//     'color:red; font-size:60px; font-weight: bold; -webkit-text-stroke: 1px black;';
//   const warningDescCSS = 'font-size: 18px;';

//   // eslint-disable-next-line no-console
//   console.log('%cStop!', warningTitleCSS);
//   // eslint-disable-next-line no-console
//   console.log(
//     '%cThis is a browser feature intended for developers. If someone told you to copy and paste something here it is most likely scam...',
//     warningDescCSS
//   );
//   // eslint-disable-next-line no-console
//   // console.log('%cSee https://www......com/selfxss for more information.', warningDescCSS);
// }, 1000);

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <App />
    </Providers>
  </React.StrictMode>,
  document.getElementById('root')
);
